<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ $t('shop.addAvailability') }}: '{{ productSet ? productSet.name : '' }}'
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>
      <hr class="mt-1">
      <div class="row align-items-center">
        <StepSelect
          class="col-9"
          :page="page"
          :enabled="filledPages"
          :list="steps"
          @select="page = $event"
        />
        <div class="col-3 text-right">
          <button
            v-if="page !== 'details'"
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled"
            @click="next"
          >
            {{ $t('general.next') }}
            <i class="fas fa-arrow-right px-2" />
          </button>
          <button
            v-else
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="pending"
            @click="save"
          >
            {{ $t('general.save') }}
            <i class="fas fa-check px-2" />
          </button>
        </div>
      </div>
      <hr class="mb-2">
      <div v-if="availabilityPending">
        <Loader />
      </div>
      <AvailabilityCalendar
        v-else-if="page === 'plan'"
        :calendar.sync="calendar"
        :first-day="firstDay"
      />
      <AvailabilityDetailsForm
        v-else-if="page === 'details'"
        :time-spec.sync="timeSpec"
        :price.sync="price"
        :name.sync="name"
        :payer-availability-script.sync="payerAvailabilityScript"
        :first-day="firstDay"
      />
    </div>
  </div>
</template>

<script>
import StepSelect from '@/components/bills/form/StepSelect';
import AvailabilityCalendar from '@/components/shop/AvailabilityCalendar';
import AvailabilityDetailsForm from '@/components/shop/AvailabilityDetailsForm';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    page: 'plan',
    productSet: null,
    name: '',
    calendar: {},
    initialCalendar: {},
    timeSpec: {},
    payerAvailabilityScript: '',
    price: [],
    firstDay: null,
    pending: false,
    availabilityPending: false,
  }),
  components: {
    AvailabilityCalendar,
    AvailabilityDetailsForm,
    StepSelect,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapGetters('shop', ['products']),
    disabled() {
      return false;
    },
    steps() {
      return [
        {
          key: 'plan',
          text: this.$t('shop.plan'),
        },
        {
          key: 'details',
          text: this.$t('payment.details'),
        },
        // TODO
        // {
        //   key: 'summary',
        //   text: this.$t('form.summary'),
        // },
      ];
    },
    filledPages() {
      const index = this.steps.findIndex((x) => x.key === this.page);
      return this.steps.flatMap((x, i) => (i <= index ? [x.key] : []));
    },
    limits() {
      const dayLimits = Object.entries(this.calendar)
        .filter(([day, products]) => products.length > 0 || this.initialCalendar[day])
        .map(([day, products]) => ({
          type: 'DayAvailabilityLimit',
          definition: JSON.stringify({ day, productIds: products.map((x) => x.id) }),
        }));

      const groupLimits = this.price.map((x) => ({
        type: 'PayersGroupAvailabilityLimit',
        definition: JSON.stringify({
          groupCode: x.groupFilter,
          price: {
            value: x.price,
            currency: x.currency,
          },
        }),
      }));

      return [
        ...dayLimits,
        ...groupLimits,
      ];
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProductSet',
      'getProducts',
      'getProductSetAvailability',
      'addProductSetAvailability',
      'addProductSetAvailability',
    ]),
    cancel() {
      this.$router.back();
    },
    next() {
      this.firstDay = this.findFirstDay();
      const index = this.steps.findIndex((x) => x.key === this.page);
      this.page = this.steps[index + 1].key;
    },
    findFirstDay() {
      const entries = Object.entries(this.calendar)
        .filter((x) => x[1].length > 0)
        .sort((a, b) => a[0].localeCompare(b[0]));

      if (entries.length > 0) {
        return entries[0][0];
      }

      return null;
    },
    requestSave() {
      const { productSetId } = this.$route.query;

      const data = {
        productSetId,
        name: this.name,
        payTo: {
          type: 'Absolute',
          value: this.timeSpec.paymentDeadline,
        },
        orderFrom: {
          type: 'Absolute',
          value: this.timeSpec.orderFrom,
        },
        orderTo: {
          type: this.timeSpec.orderTo.type,
          value: this.timeSpec.orderTo.type === 'RelativeToProduct'
            ? this.timeSpec.orderTo.time
            : this.timeSpec.orderTo.date,
        },
        cancelTo: {
          type: this.timeSpec.cancelTo.type,
          value: this.timeSpec.cancelTo.type === 'RelativeToProduct'
            ? this.timeSpec.cancelTo.time
            : this.timeSpec.cancelTo.date,
        },
        payerAvailabilityScript: this.payerAvailabilityScript,
        limits: this.limits,
      };

      return this.addProductSetAvailability({
        data,
      });
    },
    save() {
      this.pending = true;

      this.requestSave()
        .then(() => {
          this.$router.back();
        })
        .finally(() => {
          this.pending = false;
        });
    },
    initFrom({
      limits, orderFrom, orderTo, payTo, cancelTo, payerAvailabilityScript, name,
    }) {
      this.payerAvailabilityScript = payerAvailabilityScript;
      this.name = name;

      const days = limits
        .filter((x) => x.type === 'DayAvailabilityLimit')
        .map((x) => JSON.parse(x.definition));

      const groups = limits
        .filter((x) => x.type === 'PayersGroupAvailabilityLimit')
        .map((x) => JSON.parse(x.definition));

      days.forEach((d) => {
        const day = moment(d.day, 'MM/DD/YYYY').format('YYYY-MM-DD');
        const products = d.productIds.map((id) => this.products.find((y) => y.id === id));
        this.$set(this.calendar, day, products);
        this.$set(this.initialCalendar, day, products.length > 0);
      });

      this.price = groups.map((x) => ({
        groupFilter: x.groupCode,
        price: x.price.value,
        currency: x.price.currency,
      }));

      this.$set(this.timeSpec, 'orderTo', {
        type: orderTo.type,
        time: orderTo.value,
        date: orderTo.value,
      });

      this.$set(this.timeSpec, 'paymentDeadline', payTo.value);
      this.$set(this.timeSpec, 'orderFrom', orderFrom.value);
      this.$set(this.timeSpec, 'cancelTo', {
        type: cancelTo.type,
        time: cancelTo.value,
        date: cancelTo.value,
      });

      this.firstDay = this.findFirstDay();
    },
    request() {
      const { productSetId, availabilityId } = this.$route.query;

      this.getProductSet({
        params: {
          productSetId,
        },
      })
        .then(({ data }) => {
          this.productSet = data;
        });

      if (availabilityId) {
        this.availabilityPending = true;
      }

      this.getProducts()
        .then(() => {
          if (availabilityId) {
            this.getProductSetAvailability({
              params: {
                availabilityId,
                query: {
                  productSetId,
                },
              },
            })
              .then(({ data }) => {
                this.initFrom(data);
              })
              .finally(() => {
                this.availabilityPending = false;
              });
          }
        });
    },
  },
  created() {
    this.$emit('page', 'form');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }
</style>
