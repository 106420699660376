<template>
  <div
    class="listing-row"
    :class="{ selected }"
  >
    <div class="d-flex align-items-center row-content">
      <div class="font-weight-bold listing-name">
        {{ listing.name }}
      </div>
      <div
        class="text-left"
        style="min-width: 200px"
      >
        <div
          v-for="a in listingProducts"
          :key="a.id"
          class="badge assignment-badge px-2 mr-1"
        >
          {{ a.name }}
        </div>
      </div>
      <div
        v-for="s in subCatalogsSorted"
        :key="s.subCatalogId"
        v-tippy
        class="flex-grow-1 small listing-nodes"
        :content="s.nodeNames.join('<br>')"
      >
        <span v-if="s.nodes.length === 0">-</span>
        <span v-else>
          {{ s.nodeNames.join(', ') }}
        </span>
      </div>
      <div class="time-column">
        {{ listing.countLimit < 0 ? '-' : listing.countLimit }}
      </div>
      <div class="time-column">
        <TimeReferenceBadge :value="listing.orderFrom" />
      </div>
      <div class="time-column">
        <TimeReferenceBadge :value="listing.orderTo" />
      </div>
      <div class="time-column">
        <TimeReferenceBadge :value="listing.cancelTo" />
      </div>
      <div
        class="money-font text-right pr-1 money-column"
      >
        {{ formatCurrency(listing.price, 'pln') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TimeReferenceBadge from './TimeReferenceBadge';

export default {
  props: {
    listing: Object,
    nodes: Array,
    selected: Boolean,
  },
  components: {
    TimeReferenceBadge,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'groups',
    ]),
    ...mapGetters('shop', [
      'products',
      'catalogDetails',
    ]),
    subCatalogsSorted() {
      return this.catalogDetails.subCatalogs
        .slice()
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => {
          const nodes = this.getSubCatalogNodes(x.subCatalogId);
          return {
            ...x,
            nodes,
            nodeNames: nodes
              .map((n) => n.name)
              .sort((a, b) => a.localeCompare(b)),
          };
        });
    },
    listingProducts() {
      if (!this.products) return [];
      return this.listing.productIds
        .map((id) => this.products.find((p) => p.id === id))
        .filter((x) => x);
    },
    listingNodes() {
      return this.listing.nodeIds
        .map((nodeId) => {
          const group = this.groups?.find((g) => g.code === nodeId);
          if (group) {
            return {
              ...group,
              subCatalogId: '#groups',
              name: group.code === '#all' ? this.$t('company.all') : group.path,
            };
          }

          return this.catalogDetails.subCatalogNodes.find((n) => n.nodeId === nodeId);
        })
        .filter((x) => x);
    },
  },
  methods: {
    getSubCatalogNodes(subCatalogId) {
      return this.listingNodes.filter((x) => x.subCatalogId === subCatalogId);
    },
  },
};
</script>

<style lang="scss" scoped>

.listing-name {
  font-size: 14px;
  min-width: 300px;
  max-width: 300px;
}

.listing-nodes {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-width: 120px;
  flex-basis: 120px;
}

.time-column {
  min-width: 100px;
  font-size: 11px;
  text-align: left;
  display: flex;
}

.row-content {
  height: 42px;
}

.money-column {
  min-width: 120px;
  font-size: 14px
}

.listing-row {
  padding: 0px 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  &.selected {
    background-color: rgba(0, 112, 255, 0.1);
  }
}

.node-badge {
  color: #333;
  font-size: 11px;
}

.assignment-badge {
  color: #fff;
  background-color: rgba($blue, 0.8);
  font-size: 11px;
}
</style>
