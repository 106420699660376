<template>
  <div
    ref="wrapper"
    style="overflow-y: scroll; position: relative"
  >
    <BillingScopeList
      :billing-scopes="billingScopesWithBills"
      :additional-scopes="future"
      @more="showFuture"
    >
      <template #title="{ scope }">
        <!-- <Tippy
          interactive
          theme="light"
          trigger="click"
        >
          <template #trigger>
            <div class="circle-ellipsis mx-3">
              <i class="fas fa-ellipsis-v" />
            </div>
          </template>
          <a
            href="#"
            :disabled="pendingDownload"
            style="font-size: 12px; font-weight: 500"
            class="ml-2 py-0 text-dark"
            @click="downloadBills(scope.list)"
          >
            <span v-if="!pendingDownload">
              <span
                class="pr-2 position-relative icon"
              >
                <i class="fas fa-download" />
              </span>
              <span>
                {{ $t('general.export') }}
              </span>
            </span>
            <span v-else>
              <Loader
                size="12px"
                color="#333"
              />
            </span>
          </a>
        </Tippy> -->
        <div class="flex-grow-1 d-flex justify-content-end">
          <Tippy
            :ref="`scope-popup-${scope.id}`"
            interactive
            theme="light"
            trigger="click"
          >
            <template #trigger>
              <div class="light-button scope-lock">
                <i
                  v-if="scope.isOpen"
                  class="fa-solid fa-unlock"
                />
                <i
                  v-else
                  class="fa-solid fa-lock"
                />
              </div>
            </template>
            <div
              v-if="scope.isOpen"
              class="py-1"
            >
              {{ $t('bill.closeScopeInfo') }}

              <div class="pt-2">
                <button
                  class="btn btn-sm btn-primary"
                  :disabled="scopePending"
                  @click="closeScope(scope.id)"
                >
                  {{ $t('bill.closeScope') }}
                </button>
              </div>
            </div>
            <div v-else>
              {{ $t('bill.openScopeInfo') }}

              <div class="pt-2">
                <button
                  class="btn btn-sm btn-primary"
                  :disabled="scopePending"
                  @click="reopenScope(scope.id)"
                >
                  {{ $t('bill.openScope') }}
                </button>
              </div>
            </div>
          </Tippy>
        </div>
      </template>

      <template #default="{ scope }">
        <div
          v-if="scope.bills.length > 0"
          class="row no-gutters my-2 px-2 info-row"
        >
          <div :class="basic ? 'col-sm-6' : 'col-sm-4'">
            <label class="column-header">{{ $t('payment.title') }}</label>
          </div>
          <div class="col" />
          <div class="col-1 d-none d-md-block text-right column-header">
            <label class="column-header">{{ $t('menu.payers') }}</label>
          </div>
          <div class="col text-right column-header">
            <label class="column-header">{{ $t('payment.due') }}</label>
          </div>
          <Transition name="column">
            <div
              v-if="!basic"
              class="col text-right column-header"
            >
              <label class="column-header">{{ $t('payment.paid') }}</label>
            </div>
          </Transition>
          <Transition name="column">
            <div
              v-if="!basic"
              class="col d-none d-md-block text-right column-header"
            >
              <label class="column-header">{{ $t('payment.toPay') }}</label>
            </div>
          </Transition>
        </div>
        <div
          v-else
          class="ml-2 small text-secondary font-weight-bold text-uppercase mt-2"
        >
          {{ $t('bill.none') }}
        </div>

        <div
          v-for="bill in scope.bills"
          :id="`bill_${bill.id}`"
          :key="bill.id"
          data-test="single-bill"
          class="payment-container position-relative"
        >
          <BillRow
            :bill="bill"
            :basic="basic"
            :is-selected="selected === bill.id"
            @click.native="$emit('select', bill)"
          />
        </div>
      </template>
    </BillingScopeList>

    <InfinityScrollCursor
      ref="cursor"
      @request="request"
    />
    <div
      class="text-center"
      style="height: 100px"
    >
      <Loader
        v-if="pending"
      />
    </div>
  </div>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import moment from 'moment';
import { mapActions } from 'vuex';
import BillingScopeList from '../billingScopes/BillingScopeList';
import BillRow from './BillRow';

const takeMonths = 1;

export default {
  props: {
    search: {
      type: String,
      default: '',
    },
    selected: String,
    basic: Boolean,
    payerIds: Array,
    year: String,
  },
  data: () => ({
    clientAddPopover: false,
    editing: false,
    pendingDownload: false,
    pending: false,
    future: 0,
    from: moment().startOf('month').subtract(takeMonths - 1, 'months'),
    to: moment().startOf('month').add(2, 'months'),
    now: moment(),
    bills: [],
    billingScopes: [],
    scopePending: false,
    haveAllBills: false,
    destroyed: false,
  }),
  components: {
    InfinityScrollCursor,
    BillingScopeList,
    BillRow,
  },
  computed: {
    billingScopesWithBills() {
      return this.billingScopes
        .map((x) => ({
          ...x,
          bills: this.bills
            .filter((b) => b.billingScopeId === x.id)
            .sort((a, b) => a.categoryId.localeCompare(b.categoryId)),
        }));
    },
  },
  watch: {
    search() {
      this.resetFrom(moment().startOf('month'));
    },
    year(year) {
      this.resetFrom(moment(`12-${year}`, 'MM-YYYY').startOf('month'));
    },
    payerIds() {
      this.resetFrom(moment().startOf('month'));
    },
  },
  methods: {
    ...mapActions([
      'exportBill',
      'getBills',
      'closeBillingScope',
      'reopenBillingScope',
    ]),
    showFuture() {
      this.future += 6;
      this.resetFrom(moment().startOf('month'));
    },
    resetFrom(date) {
      this.haveAllBills = false;
      this.bills = [];
      this.billingScopes = [];
      this.from = moment(date).subtract(takeMonths - 1, 'months');
      this.to = moment(date).add(this.future + 1, 'months');
      this.request();
    },
    updateBill(billDetails) {
      const bill = this.bills.find((x) => x.id === billDetails.id);
      bill.title = billDetails.title;
      bill.code = billDetails.code;
      bill.description = billDetails.description;
      bill.categoryId = billDetails.categoryId;
      bill.billingScopeId = billDetails.billingScopeId;
      bill.billingDay = billDetails.billingDay;
      bill.payersCount = billDetails.payersCount;
      bill.paid = billDetails.paid;
      bill.amount = billDetails.amount;
    },
    removeBill(id) {
      this.bills = this.bills.filter((x) => x.id !== id);
    },
    closeScope(id) {
      this.scopePending = true;
      this.closeBillingScope({
        params: {
          billingScopeId: id,
        },
      })
        .then(() => {
          this.$refs[`scope-popup-${id}`].tippy().hide();
          const scope = this.billingScopes.find((x) => x.id === id);
          this.$set(scope, 'isOpen', false);
        })
        .finally(() => {
          this.scopePending = false;
        });
    },
    reopenScope(id) {
      this.scopePending = true;
      this.reopenBillingScope({
        params: {
          billingScopeId: id,
        },
      })
        .then(() => {
          this.$refs[`scope-popup-${id}`].tippy().hide();
          const scope = this.billingScopes.find((x) => x.id === id);
          this.$set(scope, 'isOpen', true);
        })
        .finally(() => {
          this.scopePending = false;
        });
    },
    request() {
      if (this.haveAllBills || this.pending || this.destroyed) return;
      this.pending = true;
      this.getBills({
        params: {
          query: {
            from: this.from.format('YYYY-MM-DD'),
            to: this.to.format('YYYY-MM-DD'),
            search: this.search || undefined,
            payerIds: this.payerIds,
          },
        },
      })
        .then(({ data }) => {
          const oldest = moment(data.oldestBillingScope, 'YYYY-MM-DD');

          const uniqueBills = data.bills.filter((x) => !this.bills.some((y) => y.id === x.id));
          const uniqueScopes = data.scopes.filter((x) => !this.billingScopes.some((y) => y.id === x.id));

          this.bills = [...this.bills, ...uniqueBills];
          this.billingScopes = [...this.billingScopes, ...uniqueScopes];

          if (oldest.isBefore(this.from)) {
            this.from = this.from.subtract(takeMonths, 'month');
            this.to = this.to.subtract(takeMonths, 'month');
            setTimeout(() => {
              this.pending = false;
              if (!this.$refs.cursor || !this.$refs.cursor.isBottom()) {
                this.request();
              }
            }, 200);
          } else {
            this.pending = false;
            this.haveAllBills = true;
          }
        });
    },

    getScopeName({ type, from }) {
      if (type === 'Month') {
        return moment(from, 'YYYY-MM-DD').format('MMMM YYYY');
      }
      if (type === 'Quarter') {
        return moment(from, 'YYYY-MM-DD').format('Qo YYYY');
      }
      if (type === 'Year') {
        return moment(from, 'YYYY-MM-DD').format('YYYY');
      }
      return '?';
    },
    downloadBills(list) {
      this.pendingDownload = true;

      const promises = list.map((p) =>
        this.exportBill({
          params: {
            billId: p.id,
          },
        })
          .then(({ data }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `${p.title}_${moment().format('DD_MM_YYYY')}`;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
          }));

      Promise.all(promises)
        .finally(() => {
          this.pendingDownload = false;
        });
    },
  },
  created() {
    this.request();
  },
  destroyed() {
    this.destroyed = true;
  },
  mounted() {
    if (this.selected) {
      this.$scrollTo(`#bill_${this.selected}`, 500, { offset: -150 });
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "bill-list-animation";

  .circle-ellipsis {
    cursor: pointer;
    text-align: center;
    color: #666;
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  .for-verification {
    position: absolute;
    color: white;
    background-color: $blue;
    border-radius: 50%;
    right: -2px;
    top: -2px;
    width: 12px;
    height: 12px;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  .selected {
    border-radius: 20px;
    margin: 20px -10px;
    padding: 20px 10px;
    box-shadow:
      0 0 30px rgba(#666, 0.2),
      0 0 10px rgba(#efefef, 0.1);
  }

  .green-row .progress-bar {
    background-color: $green !important;
  }

  .progress-container {
    position: absolute;
    height: 3px;
    width: 100%;
    top: 2px;
    padding-left: 4px;

    .progress {
      border-radius: 10px;
      height: 100%;
      background-color: white;
    }
  }

  .scope-lock {
    margin-top: -7px;
  }

  .info-row {
    font-size: 12px;
    font-weight: 400;
    padding-top: 3px;
    padding-bottom: 0px;
  }

  .slide-enter,
  .slide-leave-to { opacity: 0; max-height: 0px; }

  .slide-leave,
  .slide-enter-to { opacity: 1; max-height: 600px; }

  .slide-enter-active { transition: opacity 300ms, max-height 300ms; }
  .slide-leave-active { transition: opacity 300ms, max-height 300ms; }
</style>
