<template>
  <div />
</template>

<script>
export default {
  created() {
    this.$emit('page', 'calendar');
  },
};
</script>
