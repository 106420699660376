import { render, staticRenderFns } from "./AvailabilityDetails.vue?vue&type=template&id=55736227&scoped=true&"
import script from "./AvailabilityDetails.vue?vue&type=script&lang=js&"
export * from "./AvailabilityDetails.vue?vue&type=script&lang=js&"
import style0 from "./AvailabilityDetails.vue?vue&type=style&index=0&id=55736227&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55736227",
  null
  
)

export default component.exports