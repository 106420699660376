<template>
  <div class="payer-orders">
    <div
      class="font-weight-bold my-3 payer-name"
    >
      <PayerName
        :name="payer.name"
        :type="payer.type"
      />
      <div class="small text-secondary">
        {{ payer.code }}
      </div>
    </div>

    <div
      class="product-set-wrapper"
    >
      <div
        v-if="orders.length === 0"
        class="text-secondary text-center"
      >
        <div style="font-size: 45px">
          <i class="fas fa-empty-set" />
        </div>
        <div style="font-size: 20px">
          {{ $t('shop.noOrdersForToday') }}
        </div>
      </div>
      <div
        v-for="order in orders"
        :key="order.availabilityId"
        class="product-set"
      >
        <div
          class="row align-items-center"
        >
          <div class="product-set-icon d-none d-lg-block">
            <i class="fa-solid fa-fork-knife" />
          </div>
          <div
            class="position-relative product-set-name col-12 col-lg-auto"
          >
            {{ order.productSet.name }}
          </div>
        </div>
        <hr>

        <div
          class="paid-status d-flex align-items-center"
          :class="{
            paid: order.isPaid,
            unpaid: !order.isPaid
          }"
        >
          <i class="fa-solid fa-dollar-sign pr-2" />
          {{ order.isPaid ? $t('shop.paid') : $t('shop.notPaid') }}
        </div>

        <div
          class="paid-status delivery-status d-flex align-items-center"
          :class="order.delivered <= 1 ? 'first' : 'next'"
        >
          <i class="fa-solid fa-hand-holding-box pr-2" />
          {{ order.delivered <= 1 ? $t('shop.delivery.first') : $t('shop.delivery.next', [order.delivered]) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import { mapActions } from 'vuex';

export default {
  props: {
    payer: Object,
    orderData: Object,
  },
  components: {
    PayerName,
  },
  computed: {
    orders() {
      if (!this.orderData) return [];
      return this.orderData.orders
        .filter((x) => x.payerId === this.payer.id)
        .map((o) => ({
          ...o,
          productSet: this.orderData.productSets.find((x) => x.id === o.productSetId),
        }));
    },
  },
  methods: {
    ...mapActions('shop', [
      'reportDelivery',
    ]),
    report() {
      if (this.orders.length > 0) {
        this.reportDelivery({
          params: {
            query: {
              date: this.orders[0].day,
              payerId: this.payer.id,
            },
          },
        })
          .then(() => {
            this.$emit('delivered', this.payer.id);
          });
      }
    },
  },
  mounted() {
    this.report();
  },
};
</script>

<style lang="scss" scoped>

.delivery-status {
  &.first {
    background-color: $green;
  }

  &.next {
    background-color: $red;
  }
}

.paid-status {
  color: white;
  padding: 5px 15px;
  margin-top: 10px;
  border-radius: 10px;
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;

  i {
    width: 40px;
    text-align: center;
  }

  &.paid {
    background-color: $green;
  }

  &.unpaid {
    background-color: $red;
  }
}

.delivery {

  &.first {
    background-color: $green;
  }

  &.next {
    background-color: $red;
  }
}

.payer-orders {
  background-color: #fafafa;
  padding: 15px 30px;
  border-radius: 10px;
  height: 100%;
}

.product-set-wrapper {
  border-radius: 10px;
  padding: 3px;
  margin-bottom: 10px;
  border: 2px solid transparent;

  &.disabled {
    opacity: 0.7
  }
}

.payer-name {
  font-size: 25px;
  margin-bottom: 10px;
}

.product-set-name {
  font-weight: 600;
  font-size: 22px;
}

.product-set-icon {
  background-color: #eee;
  border-radius: 10px;
  width: 70px;
  min-width: 70px;
  font-size: 25px;
  height: 70px;
  margin-right: 15px;
  margin-left: 10px;
  line-height: 70px;
  text-align: center;
}

.product-set {
  box-shadow: 0 2px 4px rgba(#aaa, 0.2), 0 -2px 4px rgba(#aaa, 0.1);
  border-radius: 10px;
  padding: 20px 25px;
  border: 2px solid transparent;
  background-color: white;
  position: relative;
  margin-bottom: 15px;

  &.selected {
    border-color: $blue;
  }
}
</style>
