<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="800"
    placement="top"
    @show="opened"
  >
    <template #trigger>
      <slot
        name="trigger"
        :selected="selected"
      >
        <div class="sort-option-selected d-flex align-items-center">
          <i class="fas fa-plus" />
        </div>
      </slot>
    </template>

    <div
      style="width: 400px"
    >
      <div style="padding-bottom: 5.5px">
        <SearchBox
          ref="search"
          v-model="search"
          :height="30"
          constant-width
          @enter="selectFirst"
          @cancel="hide"
        />
      </div>
      <div
        style="height: 300px; overflow: auto"
      >
        <div
          v-for="s in filteredProducts"
          :key="s.key"
          class="sort-option text-left d-flex pl-0"
          @click="toggle(s)"
        >
          <div class="flex-grow-1 pl-2">
            {{ s.name }}
          </div>
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import createSearch from '@/utils/search';
import { mapGetters } from 'vuex';

export default {
  props: {
    initOpen: Boolean,
    skipProductIds: {
      type: Array,
      default: () => [],
    },
    showInline: {
      default: 3,
      type: Number,
    },
  },
  data: () => ({
    selected: [],
    search: '',
  }),
  computed: {
    ...mapGetters('shop', ['products']),
    filteredProducts() {
      if (!this.products) return [];
      const search = createSearch(this.search);

      return this.products
        .filter((p) => !this.skipProductIds.includes(p.id))
        .filter((x) => this.search.length === 0 || search(x.name));
    },
  },
  watch: {
    payerIds(ids) {
      this.selected = this.payers.filter((x) => ids.includes(x.id));
    },
    search() {
      this.skip = 0;
    },
  },
  methods: {
    selectFirst() {
      if (this.filteredProducts.length > 0) {
        this.toggle(this.filteredProducts[0]);
      }
    },
    open() {
      this.$refs.popover.tippy().show();
    },
    hide() {
      this.$refs.popover.tippy().hide();
    },
    toggle(s) {
      this.$emit('selected', s);
    },
    isSelected(id) {
      return this.selected.some((x) => x.id === id);
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
  mounted() {
    if (this.initOpen) {
      this.open();
    }
  },
};
</script>

<style lang="scss" scoped>
 @import '../payers/style.scss';

  .sort-option-selected {
    position: relative;
    min-height: auto;
    padding: 10px;
    border-radius: 50%;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    font-size: 20px;
    width: 24px;
    right: 10px;
    top: 11px;
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    color: #888;

    &:hover {
      color: #333;
      background-color: #ddd;
    }
  }
</style>
