<template>
  <div
    class="d-flex flex-wrap flex-md-nowrap"
    :class="hideList ? 'justify-content-center list-hidden' : ''"
  >
    <div
      v-if="!hideList"
      class="pb-2 mr-2 editable-list"
      :style="{ width: `${width}px`}"
    >
      <div
        v-if="!hideAdd"
        class="mb-2"
      >
        <div
          class="add-client-property client-property"
          :class="{ selected: selected === -1 }"
          @click="toggle(-1)"
        >
          <i class="fas fa-plus pr-2" />
          {{ $t('general.add') }}
        </div>
      </div>

      <slot
        name="list"
        :list="list"
        :toggle="toggle"
        :selected="selected"
      >
        <div
          v-for="p in list"
          :key="p.id"
          class="client-property d-flex justify-content-between"
          :class="{ selected: selected === p.id }"
          @click="toggle(p.id)"
        >
          <slot
            name="listElement"
            :row="p"
          >
            <span>
              {{ p.name }}
            </span>
          </slot>
        </div>
      </slot>
    </div>

    <Transition
      name="client-details"
      mode="out-in"
    >
      <div
        v-if="formReady && selected"
        class="settings-form"
        :style="{
          width: `${width}px`,
          position: sticky ? 'sticky' : undefined,
          top: sticky ? '0px' : undefined,
        }"
      >
        <form @submit.prevent="submit">
          <div style="margin: auto">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">
                {{ selected === -1 ? formAddTitle : formEditTitle }}
              </div>
              <button
                type="button"
                aria-label="Close"
                class="close"
                @click="toggle(selected)"
              >
                ×
              </button>
            </div>
            <slot
              ref="form"
              name="form"
              class="mt-2"
              :edited="selected"
            />
            <hr class="my-3">
            <slot
              name="submit"
              :edited="selected"
            >
              <div class="d-flex justify-content-between">
                <RemoveButton
                  v-if="selected !== -1 && !hideRemove"
                  :remove-warning="removeWarning"
                  @confirm="remove"
                />
                <div />
                <div class="text-nowraptext-right">
                  <button
                    :disabled="pending || !valid"
                    class="btn btn-primary btn-sm"
                    type="submit"
                  >
                    <i class="fas fa-check pr-2" />

                    <span v-if="selected === -1">
                      {{ $t('general.add') }}
                    </span>
                    <span v-else>
                      {{ $t('general.save') }}
                    </span>
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </form>
      </div>
    </Transition>
  </div>
</template>

<script>
import RemoveButton from '@/components/utils/RemoveButton';

export default {
  props: {
    list: Array,
    formAddTitle: String,
    formEditTitle: String,
    pending: Boolean,
    removeWarning: String,
    hideAdd: Boolean,
    sticky: Boolean,
    hideRemove: Boolean,
    hideList: Boolean,
    valid: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: 370,
    },
  },
  data: () => ({
    formReady: false,
    selected: null,
  }),
  components: {
    RemoveButton,
  },
  methods: {
    remove() {
      if (!this.pending);
      this.$emit('remove');
    },
    submit() {
      this.$emit('submit');
    },
    close() {
      this.toggle(this.selected);
    },
    toggle(id) {
      if (this.selected === id) {
        this.selected = null;
      } else {
        this.selected = id;
      }

      this.$emit('selected', this.selected);
      this.formReady = false;
      setTimeout(() => {
        this.formReady = true;
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>

  .group-border {
    padding: 1rem;
  }

  .client-property {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
    &.selected {
      margin-right: -20px;
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }

  .add-client-property {
    border: 2px solid $blue;
    background-color: $blue;
    color: white;

    &:hover {
      border-color: $darkblue;
    }

    &.selected {
      margin-right: -20px;
      background-color: $darkblue;
      border-color: $darkblue;
    }
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }

  .settings-form {
    border: 1px solid #eee;
    padding: 15px;
    margin-left: 30px;
    width: 370px;
    border-radius: 15px;
    align-self: start;
  }

  .remove-icon {
    color: #888;
    cursor: pointer;

    &:hover {
      color: $red;
    }
  }

  .list-hidden {
    .settings-form {
      margin-left: 0;
    }
  }

  .close {
    font-size: 28px;
    padding-right: 2px;
    margin-top: -2px;
  }

  @media screen and (max-width: 800px) {
    .editable-list {
      width: 100% !important;
      min-height: 0;
    }

    .settings-form {
      width: 100%;
      margin-left: 0;
    }
  }
</style>
