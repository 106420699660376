<template>
  <div>
    <TimeAbsoluteInput
      v-if="singleOrderSchema"
      :value="value"
      :label="label"
      @update:value="$emit('update:value', $event)"
    />
    <TimeReferenceInput
      v-else
      :value="value"
      @update:value="$emit('update:value', $event)"
    />
  </div>
</template>

<script>
import moment from 'moment';
import TimeAbsoluteInput from './TimeAbsoluteInput';
import TimeReferenceInput from './TimeReferenceInput';

export default {
  props: {
    value: String,
    label: String,
    defaultReference: String,
    singleOrderSchema: Boolean,
  },
  data: () => ({
  }),
  components: {
    TimeReferenceInput,
    TimeAbsoluteInput,
  },
  created() {
    if (!this.value) {
      if (this.singleOrderSchema) {
        this.$emit('update:value', moment().format('YYYY-MM-DD'));
      } else {
        this.$emit('update:value', this.defaultReference);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
