<template>
  <CtkDateTimePicker
    v-model="innerValue"
    format="YYYY-MM-DD HH:mm"
    formatted="DD MMM YYYY HH:mm"
    :label="label"
    :locale="locale"
    @input="$emit('update:value', $event)"
  />
</template>

<script>
import CtkDateTimePicker from 'vue-ctk-date-time-picker';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: String,
    label: String,
  },
  data: () => ({
    innerValue: '',
  }),
  components: {
    CtkDateTimePicker,
  },
  computed: {
    ...mapGetters(['locale']),
  },
  watch: {
    value(v) {
      this.innerValue = v;
    },
  },
  created() {
    this.innerValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
.time-reference-box {
  border: 1px solid #efefef;
  background-color: rgba(#efefef, 0.1);
  padding: 10px;
  border-radius: 10px;
}
</style>
