<template>
  <div>
    <div
      v-if="error"
      class="settings-box"
    >
      <span
        class="text-danger small"
      >
        {{ $t('error.settingsUnavailable') }}
      </span>
    </div>
    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="section-title">
        {{ $t('menu.shop') }}
      </div>
      <SwitchWithDescription
        class="mt-2"
        :title="$t('shop.settings.module')"
        :description="$t('shop.settings.enabledInfo')"
        :pending="pending || error"
        :value="enabled"
        @switch="updateEnabled($event.toString())"
      />
      <div class="section-title mt-4">
        {{ $t('shop.timeToPay') }}
      </div>
      <SwitchWithDescription
        class="mt-2"
        :title="$t('shop.cancelAutomatically')"
        :description="$t('shop.cancelAutomaticallyInfo')"
        :pending="pending || error"
        :value="orderAutoCancel"
        @switch="updateOrderAutoCancel($event, orderAutoCancelTime)"
      />
      <div
        class="d-flex align-items-center mt-3 p-4 cancel-duration"
      >
        <TimeSpanInput
          v-model="orderAutoCancelTime"
          style="max-width: 500px"
          :style="{
            opacity: !orderAutoCancel ? '0.5' : 1
          }"
        />

        <div class="ml-2">
          <button
            class="btn btn-sm btn-primary"
            style="width: 100px"
            :disabled="pending || orderAutoCancelTime === initOrderAutoCancelTime"
            @click="updateOrderAutoCancel(orderAutoCancel, orderAutoCancelTime)"
          >
            <span v-if="autoCancelSuccess">
              <i class="fas fa-check" />
            </span>
            <span v-else>
              {{ $t('general.save') }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="section-title">
        {{ $t('settings.billingScope') }}
      </div>
      <div class="container-small pt-2">
        <BFormSelect
          v-model="billingScopeType"
          :disabled="!enabled"
          :options="billingScopeTypes"
          @input="setBillingScopeType"
        />
      </div>
    </div>

    <div
      class="settings-box position-relative"
      :class="{ disabled: error }"
    >
      <div class="section-title">
        {{ $t('shop.settings.productSetTypes') }}
      </div>
      <div class="pt-2">
        <ProductSetTypes
          :list="productSetTypes"
          @updated="init"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TimeSpanInput from '@/components/TimeSpanInput';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import ProductSetTypes from '@/components/settings/shop/ProductSetTypes';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    enabled: false,
    pending: false,
    billingScopeType: 'Month',
    previousData: null,
    allowMultipleSets: false,
    productSetTypes: [],
    error: false,
    orderAutoCancel: false,
    orderAutoCancelTime: '0.00:15:00',
    initOrderAutoCancelTime: '0.00:15:00',
    orderCleanupDate: null,
    autoCancelSuccess: false,
  }),
  components: {
    SwitchWithDescription,
    ProductSetTypes,
    TimeSpanInput,
  },
  computed: {
    ...mapGetters(['payee', 'bankAccounts']),
    sortedAccounts() {
      return this.bankAccounts
        .map((x) => ({
          text: x.name,
          value: x.accountNumber,
        }));
    },
    billingScopeTypes() {
      return [
        {
          value: 'Week',
          text: this.$t('time.week'),
        },
        {
          value: 'Month',
          text: this.$t('time.month'),
        },
        {
          value: 'Quarter',
          text: this.$t('time.quarter'),
        },
        {
          value: 'Year',
          text: this.$t('time.year'),
        },
      ];
    },
    defaultCategory() {
      return this.payee?.categories.find((x) => x.isDefault);
    },
  },
  watch: {
    bankAccounts() {
      if (!this.bankAccountId) {
        this.initBankAccount();
      }
    },
  },
  methods: {
    ...mapActions('shop', [
      'getShopConfig',
      'toggleShopEnabled',
      'toggleShopScopeType',
      'toggleShopOrderAutoCancel',
    ]),
    setBillingScopeType(value) {
      this.pending = true;
      this.toggleShopScopeType({
        params: {
          query: {
            scopeType: value,
          },
        },
      })
        .then(({ data }) => {
          this.init(data);
          this.pending = false;
        })
        .catch(() => {
          this.init(this.previousData);
          this.pending = false;
        });
    },
    updateEnabled(value) {
      this.pending = true;
      this.toggleShopEnabled({
        params: {
          query: {
            enabled: value,
          },
        },
      })
        .then(({ data }) => {
          this.init(data);
          this.$store.commit('triggerShopEnabled', data.enabled);
          this.pending = false;
        })
        .catch(() => {
          this.init(this.previousData);
          this.pending = false;
        });
    },
    updateOrderAutoCancel(value, time) {
      this.pending = true;
      this.toggleShopOrderAutoCancel({
        data: {
          enabled: value,
          time,
        },
      })
        .then(({ data }) => {
          this.init(data);
          this.autoCancelSuccess = true;
          setTimeout(() => {
            this.autoCancelSuccess = false;
          }, 2000);
          this.pending = false;
        })
        .catch(() => {
          this.init(this.previousData);
          this.pending = false;
        });
    },
    initBankAccount() {
      if (this.sortedAccounts.length > 0) {
        this.bankAccountId = this.sortedAccounts[0].value;
      }
    },
    init(data) {
      this.enabled = data.enabled;
      this.billingScopeType = data.scopeType;
      this.allowMultipleSets = data.allowMultipleSets;
      this.productSetTypes = data.productSetTypes;
      this.orderAutoCancelTime = data.orderAutoCancelTime || '0.00:15:00';
      this.initOrderAutoCancelTime = data.orderAutoCancelTime || '0.00:15:00';
      this.orderAutoCancel = data.orderAutoCancel;
      this.orderCleanupDate = data.orderCleanupDate;
      this.previousData = data;
    },
  },
  created() {
    this.$emit('page', 'shop');

    this.getShopConfig()
      .then(({ data }) => {
        this.init(data);
      })
      .catch(() => {
        this.error = true;
      });
  },
};
</script>

<style lang="scss">
.form-cell {
  padding: 5px 0;
}

.cancel-duration {
  width: 600px;
  border: 1px solid #eee;
  border-radius: 15px;
}
</style>
