<template>
  <div
    v-if="payee?.shopEnabled"
    class="h-100 position-relative"
  >
    <Catalogs />
  </div>
  <div
    v-else
    class="shop-baner"
  >
    <i class="far fa-store" />
    <div v-html="$t('shop.thisWillBeAShop')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Catalogs from './catalogs/Catalogs';

export default {
  data: () => ({
    page: 'shop',
  }),
  components: {
    Catalogs,
  },
  computed: {
    ...mapGetters(['payeeId', 'payee']),
  },
  created() {
    this.$emit('page', 'shop');
  },
};
</script>

<style lang="scss" scoped>

  .light-button {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  .shop-baner {
    padding-top: 50px;
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
    color: #aaa;

    i {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
</style>
