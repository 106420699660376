<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t('general.groupBy') }}
        </div>
        <div
          class="first"
          style="font-size: 14px"
        >
          <i :class="`${groupBy.icon} pr-1`" />
          {{ groupBy.text }}
        </div>
      </div>
    </template>

    <div style="min-width: 180px">
      <div
        v-for="(s, i) in groupOptions"
        :key="s.key"
        class="sort-option text-left"
        :class="{ first: i === 0, active: groupKey === s.key }"
        @click="$emit('update:groupKey', s.key); $refs.popover.tippy().hide()"
      >
        <i :class="`${s.icon} pr-1`" />
        {{ s.text }}
      </div>
    </div>
  </Tippy>
</template>

<script>
export default {
  props: {
    groupKey: String,
  },
  computed: {
    groupBy() {
      return this.groupOptions.find((x) => x.key === this.groupKey);
    },
    // groupOptions() {
    //   return [
    //     {
    //       key: 'payers',
    //       icon: 'far fa-users',
    //       text: this.$t('menu.payers'),
    //     },
    //     {
    //       key: 'offer',
    //       icon: 'far fa-boxes-stacked',
    //       text: this.$t('shop.offer'),
    //     },
    //     {
    //       key: 'products',
    //       icon: 'far fa-tag',
    //       text: this.$t('shop.products'),
    //     },
    //   ];
    // },
    groupOptions() {
      return [
        {
          key: 'payers',
          icon: 'fal fa-users',
          text: this.$t('menu.payers'),
        },
        {
          key: 'productSets',
          icon: 'far fa-boxes-stacked',
          text: this.$t('shop.productSets'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
 @import '@/components/payers/style.scss';
</style>
