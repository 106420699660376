<template>
  <div
    class="product-set-wrapper"
    :class="{
      ordered,
      paid,
      'not-paid': !paid,
      'is-returned': returned,
      disabled: (!ordered && !selected && availableCount === 0)
    }"
  >
    <div
      v-if="ordered"
      class="status-badge d-flex justify-content-between"
    >
      <div>
        <i
          class="fas pr-2"
          :class="paid ? 'fa-check' : 'fa-exclamation'"
        />
        <span
          v-if="summary"
          class="paid-status pr-2"
        >
          <span v-if="paidCount > 0">
            {{ $t('shop.paidPlural') }}: {{ paidCount }}
          </span>
          <span
            v-if="paidCount > 0 && unpaidCount > 0"
            class="px-2"
          >
            /
          </span>
          <span v-if="unpaidCount > 0">
            {{ $t('shop.notPaidPlural') }}: {{ unpaidCount }}
          </span>
        </span>
        <span
          v-else
          class="paid-status pr-2"
        >
          {{ paid ? $t('shop.paid') : $t('shop.notPaid') }}
        </span>
      </div>
      <div v-if="canCancel && cancelTo">
        {{ $t('shop.cancelTo') }}:
        {{ cancelDeadline }}
      </div>
      <div v-else-if="cancelTo">
        {{ $t('shop.cancellationExpired') }}
      </div>
    </div>

    <div
      class="product-set"
      :class="{
        selected
      }"
    >
      <div
        class="row align-items-center product-set-row"
      >
        <div v-if="!showProducts && payeeId != 'ad158bc6-8aff-4614-afd7-49679036f7e3'">
          <div class="product-set-icon d-none d-xl-block">
            <i class="fa-solid fa-fork-knife" />
          </div>
        </div>

        <div
          v-if="selected"
          class="count-icon"
        >
          {{ selected }}
        </div>
        <div
          class="col-12 col-xl-auto flex-grow-1 ml-3"
          :style="showProducts ? 'width: calc(100% - 200px)' : 'width: calc(100% - 300px)'"
        >
          <div
            class="position-relative product-set-name"
          >
            {{ productSet.name }}
          </div>
          <div
            v-if="returned"
            class="product-set-description text-warning"
          >
            {{ $t('shop.toBeCanceled') }}
          </div>
          <div
            v-else-if="showDescription && productSet.description"
            class="product-set-description"
          >
            {{ productSet.description || '' }}
          </div>
          <div class="font-weight-normal pb-1">
            {{ $t('shop.price') }}:
            <span v-if="minPrice === maxPrice">
              {{ formatCurrency(minPrice, currency) }}
            </span>
            <span v-else>
              {{ formatCurrency(minPrice, currency, true) }} -
              {{ formatCurrency(minPrice, currency) }}
            </span>
            <span class="text-lowercase">
              / szt
            </span>
          </div>
          <div
            v-if="summary"
            class="product-set-description"
          >
            {{ $t('shop.available') }}: {{ availableCount }}
          </div>
          <div
            v-if="ordered && summary"
            class="product-set-description"
          >
            {{ $t('shop.ordered') }}: {{ ordered }}
          </div>
        </div>
        <div
          class="col-12 col-xl-auto"
          style="width: 200px"
        >
          <hr
            v-if="showInBasket || !ordered || summary || canCancel"
            class="mb-2 d-xl-none"
          >
          <div
            class="row justify-content-end no-gutters w-100"
          >
            <div
              v-if="ordered && summary"
              class="my-2 px-2 col col-md-auto flex-grow-1"
              style="max-width: 190px"
            >
              <button
                v-if="!!firstCancelAvailable || availableCount > 0"
                class="btn btn-block btn-sm btn-outline-primary text-nowrap"
                @click="$emit('showCalendar')"
              >
                <i class="far fa-calendar-days pr-1" />
                <span v-if="availableCount > 0">
                  {{ $t('shop.order') }}
                </span>
                <span v-if="firstCancelAvailable && availableCount > 0"> / </span>
                <span v-if="firstCancelAvailable">{{ $t('shop.cancel') }}</span>
              </button>
            </div>
            <div
              v-else-if="!ordered"
              class="my-2 px-2 col col-md-auto flex-grow-1"
              style="max-width: 190px"
            >
              <button
                :disabled="!selected && availableCount === 0"
                class="btn btn-block btn-sm text-nowrap"
                :class="selected ? 'btn-outline-primary' : 'btn-primary'"
                @click="$emit('order')"
              >
                <span v-if="!selected">
                  {{ $t('shop.order') }}
                </span>
                <span v-else>
                  {{ $t('general.undo') }}
                </span>
              </button>
            </div>
            <div
              v-else-if="canCancel"
              class="my-2 px-2 col col-md-auto flex-grow-1 text-nowrap"
              style="max-width: 190px"
            >
              <button
                class="btn btn-sm small btn-block btn-outline-primary"
                @click="$emit('cancel')"
              >
                <span v-if="!returned">
                  {{ $t('shop.cancel') }}
                </span>
                <span v-else>
                  {{ $t('general.undo') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <template v-if="showProducts">
        <hr class="mt-2 mb-1">
        <div
          v-for="p in products"
          :key="p.id"
          class="d-flex align-items-center mt-2 p-1 product-info"
        >
          <div class="product-icon mr-3">
            <i class="fas fa-pot-food" />
          </div>
          <div class="pt-1">
            <div class="product-title smal">
              {{ p.name }}
            </div>
            <div class="product-description text-secondary">
              {{ p.description }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    payeeId: String,
    selected: Number,
    productSet: Object,
    products: Array,
    minPrice: Number,
    maxPrice: Number,
    currency: String,
    ordered: [Number, Boolean],
    paid: Boolean,
    returned: Boolean,
    availableCount: Number,
    unpaidCount: Number,
    paidCount: Number,
    cancelTo: String,
    firstCancelAvailable: String,
    showProducts: Boolean,
    summary: Boolean,
    showInBasket: Boolean,
    showDescription: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    canCancel() {
      if (!this.cancelTo) return true;
      return moment(this.cancelTo).isAfter(moment());
    },
    cancelDeadline() {
      if (!this.cancelTo) return null;
      return moment(this.cancelTo).format('HH:mm DD/MM');
    },
    setTags() {
      return Object.keys(this.products
        .flatMap((x) => x.tags)
        .reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {}));
    },
  },
};
</script>

<style lang="scss" scoped>

.product-set-wrapper {
  background-color: #fafafa;
  border-radius: 10px;
  padding: 3px;
  margin-bottom: 10px;
  border: 2px solid transparent;

  &.disabled {
    opacity: 0.7
  }
}

.is-returned {
  .product-set-name {
    text-decoration: line-through;
  }
}

.cancelled-info {
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 12px;
  color: $orange;
}

.product-set-name {
  font-weight: 600;
}

.product-set-description {
  font-size: 12px;
  color: #666;
}

.product-info {
  .badge {
    font-size: 10px;
    padding: 3px 8px;
    color: #666;
    border: 1px solid #666;
  }
}

.product-icon {
  background-color: #eee;
  border-radius: 10px;
  width: 60px;
  min-width: 60px;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.product-set-icon {
  background-color: #eee;
  border-radius: 10px;
  width: 70px;
  min-width: 70px;
  font-size: 25px;
  height: 70px;
  margin-right: 15px;
  margin-left: 10px;
  line-height: 70px;
  text-align: center;
}

.product-title {
  font-weight: 500;
  font-size: 13px;
}

.product-description {
  font-size: 12px;
}

.product-set {
  box-shadow: 0 2px 4px rgba(#aaa, 0.2), 0 -2px 4px rgba(#aaa, 0.1);
  border-radius: 10px;
  padding: 20px 25px;
  border: 2px solid transparent;
  background-color: white;
  position: relative;

  &.selected {
    border-color: $blue;
  }
}

.ordered {
  background-color: $lightblue;
  border-radius: 5px;

  &.not-paid {
    background-color: $orange;
    .product-set {
      border-color: $orange;

      .count-icon {
        background-color: $orange;
      }
    }
    .info-text {
      color: $orange;
    }
  }
  &.paid {
    .product-set {
      border-color: $lightblue;
      .count-icon {
        background-color: $lightblue;
      }
    }
    .info-text {
      color: #aaa;
    }
  }

  .status-badge {
    color: white;
    padding: 1px 5px 2px;
    font-weight: 500;
    font-size: 11px;
  }
}

.count-icon {
  background-color: $blue;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
}

@media screen and (min-width: 1200px) {
  .product-set-row {
    flex-wrap: nowrap;
  }
}
</style>
