<template>
  <div>
    <BModal
      v-model="catalogForm"
      hide-footer
      hide-header
      size="lg"
    >
      <CatalogForm
        :catalog="edited"
        @close="catalogForm = false"
        @refresh="request"
      />
    </BModal>

    <template v-if="!edited">
      <div class="d-flex justify-content-between">
        <div class="page-title-wrapper">
          <div class="page-title active">
            {{ $t('shop.catalog.catalogs') }}
          </div>
        </div>
        <div class="page-title-wrapper">
          <button
            class="btn btn-sm btn-primary"
            @click="catalogForm = true"
          >
            <i class="fas fa-plus mr-1" />
            {{ $t('shop.catalog.add') }}
          </button>
        </div>
      </div>
      <Loader v-if="pending" />
      <CatalogRow
        v-for="c in catalogs"
        :key="c.id"
        :catalog="c"
        :selected="c.id === editedId"
        class="mb-2"
        @edit="$router.push(`/payee/${payeeId}/shop/${c.id}/orders`)"
      />
    </template>
    <CatalogOffer
      v-else
      :catalog="edited"
      @close="editedId = null"
      @edit="catalogForm = true"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CatalogForm from './CatalogForm';
import CatalogOffer from './CatalogOffer';
import CatalogRow from './CatalogRow';

export default {
  data: () => ({
    editedId: null,
    catalogForm: false,
    pending: false,
  }),
  components: {
    CatalogOffer,
    CatalogRow,
    CatalogForm,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapGetters('shop', ['catalogs']),
    edited() {
      return this.catalogs.find((x) => x.id === this.editedId);
    },
  },
  methods: {
    ...mapActions('shop', ['getShopCatalogs']),
    request() {
      this.pending = true;
      this.getShopCatalogs()
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'catalogs');
    this.request();
  },
};
</script>

<style lang="scss" scoped>

</style>
