<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="page-header">
        <h1>
          {{ $t('shop.import') }}
        </h1>
      </div>
      <div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="cancel"
        >
          ×
        </button>
      </div>
    </div>
    <hr class="mt-0">
    <div
      v-if="!uploadPending"
      class="d-flex"
    >
      <FileUpload
        class="upload mr-4"
        @upload="validate"
      />
      <div>
        <SwitchWithDescription
          :title="$t('shop.addMissingProducts')"
          small
          class="mb-1"
          style="min-height: 60px; margin-top: 0"
          :value="createMissingProducts"
          @switch="createMissingProducts = !createMissingProducts"
        />
      </div>
    </div>
    <div
      v-else
      class="upload"
    >
      <Loader />
    </div>

    <div
      v-if="uploadError"
      class="text-danger"
    >
      {{ $t('shop.error.UnableToParse') }}
    </div>

    <div
      v-if="validationResult"
      class="validation-result"
    >
      <div v-if="valid">
        <div class="d-flex align-items-center justify-content-between">
          <div class="my-4 d-flex align-items-center">
            <i class="fa-regular file-icon fa-file-circle-check text-primary pr-3" />
            <div class="font-weight-bold text-primary">
              {{ $t('import.noIssues') }}
            </div>
          </div>
          <div>
            <button
              class="btn btn-sm btn-primary mt-2"
              :disabled="uploadPending"
              @click="upload"
            >
              {{ $t('shop.confirmImport') }}
              <i class="fa-regular fa-arrow-right pl-2" />
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="pb-4"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div class="my-4 d-flex align-items-center">
            <i class="fa-regular file-icon fa-file-circle-info text-warning pr-3" />
            <div class="font-weight-bold text-warning">
              {{ $t('import.thereAreSomeIssues') }}
            </div>
          </div>
          <div>
            <button
              class="btn btn-sm btn-secondary mt-2 mr-1"
              :disabled="uploadPending"
              @click="validate(file)"
            >
              {{ $t('shop.revalidate') }}
              <i class="fas fa-rotate-right pl-2" />
            </button>
            <button
              class="btn btn-sm btn-primary mt-2"
              :disabled="uploadPending"
              @click="upload"
            >
              {{ $t('shop.confirmImport') }}
              <i class="fa-regular fa-arrow-right pl-2" />
            </button>
          </div>
        </div>
        <div v-if="validationResult.productErrors.length > 0">
          <div class="section-title">
            {{ $t('shop.products') }}
          </div>
          <div
            v-for="(error, i) in validationResult.productErrors"
            :key="`product_${i}`"
          >
            <ShopImportError v-bind="error" />
          </div>
          <hr>
        </div>
        <div v-if="validationResult.availabilityErrors.length > 0">
          <div class="section-title">
            {{ $t('shop.availability') }}
          </div>

          <div
            v-for="(error, i) in validationResult.availabilityErrors"
            :key="`availability_${i}`"
          >
            <ShopImportError v-bind="error" />
          </div>
        </div>

        <div v-if="validationResult.scheduleErrors.length > 0">
          <div class="section-title">
            {{ $t('shop.plan') }}
          </div>
          <div
            v-for="(error, i) in validationResult.scheduleErrors"
            :key="`schedule_${i}`"
          >
            <ShopImportError v-bind="error" />
          </div>
        </div>
        <div v-if="validationResult.otherErrors.length > 0">
          <div class="section-title">
            {{ $t('shop.other') }}
          </div>
          <div
            v-for="(error, i) in validationResult.otherErrors"
            :key="`schedule_${i}`"
          >
            <ShopImportError v-bind="error" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import ShopImportError from '@/components/shop/ShopImportError';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    uploadPending: false,
    validationResult: null,
    createMissingProducts: false,
    file: null,
    uploadError: false,
  }),
  components: {
    FileUpload,
    SwitchWithDescription,
    ShopImportError,
  },
  computed: {
    ...mapGetters(['payeeId']),
    valid() {
      if (!this.validationResult) return false;
      return this.validationResult.productErrors.length === 0
      && this.validationResult.availabilityErrors.length === 0
      && this.validationResult.scheduleErrors.length === 0
      && this.validationResult.otherErrors.length === 0;
    },
  },
  methods: {
    ...mapActions('shop', [
      'validateShopImport',
      'uploadShopImport',
    ]),
    cancel() {
      this.$router.push(`/payee/${this.payeeId}/shop/${this.catalogId}/sets_v1`);
    },
    validate(file) {
      this.uploadPending = true;
      this.uploadError = false;
      const formData = new FormData();
      this.file = file;
      this.validationResult = null;
      formData.append('file', file);
      formData.append('createMissingProducts', this.createMissingProducts);

      this.validateShopImport({
        data: formData,
      })
        .then(({ data }) => {
          this.validationResult = data;
        })
        .catch(() => {
          this.uploadError = true;
        })
        .finally(() => {
          this.uploadPending = false;
        });
    },
    upload() {
      this.uploadPending = true;
      this.uploadError = false;
      const formData = new FormData();

      formData.append('file', this.file);
      formData.append('createMissingProducts', this.createMissingProducts);

      this.uploadShopImport({
        data: formData,
      })
        .then(() => {
          this.cancel();
        })
        .catch(() => {
          this.uploadError = true;
        })
        .finally(() => {
          this.uploadPending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'form');
  },
};
</script>

<style lang="scss" scoped>
.file-icon {
  font-size: 35px;
}

.upload {
  min-height: 20px;
  padding: 5px 15px;
  max-width: 700px;
  margin: 0;
}

.upload ::v-deep .dz-default {
  margin: 1em 0;
}

.section-title {
  font-weight: 500;
  font-size: 15px;
}

.validation-result {
  height: calc(100vh - 213px);
  overflow: auto;
}

.close {
  font-size: 40px;
}
</style>
