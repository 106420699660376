<template>
  <Tippy
    trigger="click"
    interactive
    theme="light"
    placement="left"
    @show="opened"
  >
    <template #trigger>
      <i class="fas fa-plus secondary-icon" />
    </template>

    <div style="padding-bottom: 5.5px">
      <SearchBox
        ref="search"
        v-model="search"
        :height="30"
        constant-width
      />
    </div>
    <div class="products-dropdown dropdown-scroll">
      <div
        v-for="p in filteredProducts"
        :key="p.id"
        class="sort-option"
        :class="{ active: selected.includes(p.id) }"
        @click="toggle(p.id)"
      >
        <i
          v-if="selected.includes(p.id)"
          class="fa-regular fa-circle-check pr-1"
        />
        {{ p.name }}
      </div>
      <div
        v-if="allowCreate"
        class="sort-option"
        :class="{ active: selected.includes('#new') }"
        @click="toggle('#new')"
      >
        <i
          class="far fa-circle-plus pr-1"
        />
        {{ $t('shop.listing.addSameAsListing') }}
      </div>
    </div>
  </Tippy>
</template>

<script>
import createSearch from '@/utils/search';
import { mapGetters } from 'vuex';

export default {
  props: {
    selected: Array,
    allowCreate: Boolean,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters('shop', ['products']),
    filteredProducts() {
      const search = createSearch(this.search);
      return this.products
        .filter((x) => search(x.name));
    },
  },
  methods: {
    toggle(id) {
      if (this.selected.includes(id)) {
        this.$emit('update:selected', this.selected.filter((x) => x !== id));
      } else {
        this.$emit('update:selected', [...this.selected, id]);
      }
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/payers/style.scss';

.products-dropdown {
  text-align: left;
}
</style>
