import { render, staticRenderFns } from "./ProductDropdown.vue?vue&type=template&id=1b966547&scoped=true&"
import script from "./ProductDropdown.vue?vue&type=script&lang=js&"
export * from "./ProductDropdown.vue?vue&type=script&lang=js&"
import style0 from "./ProductDropdown.vue?vue&type=style&index=0&id=1b966547&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b966547",
  null
  
)

export default component.exports