<template>
  <div>
    <div>
      <div class="form-cell">
        <label>{{ $t('general.name') }}</label>
        <BFormInput
          v-model="name"
          :placeholder="$t('general.name')"
        />
      </div>
      <div class="d-flex">
        <div class="w-50 form-cell">
          <label>{{ $t('general.description') }}</label>
          <BFormTextarea
            v-model="description"
            :placeholder="$t('general.description')"
          />
        </div>
        <div class="w-50 form-cell">
          <label>{{ $t('shop.tagsAlergens') }}</label>
          <div
            v-for="(t, index) in tags"
            :key="index"
            class="d-flex align-items-center mb-2"
          >
            <BFormInput
              :key="index"
              :ref="`tag-${index}`"
              v-model="tags[index]"
              :placeholder="$t('shop.tag')"
              class="flex-grow-1"
            />
            <div
              class="product-remove"
              @click="removeTag(t, index)"
            >
              <i class="far fa-trash" />
            </div>
          </div>
          <div
            class="secondary-icon bill-button px-3"
            style="width: 100px"
            @click="addTag"
          >
            <i class="far fa-add mr-2" />
          </div>
        </div>
      </div>
      <hr>
      <div
        class="row"
        style="width: 400px; margin-left: auto; margin-right: 0"
      >
        <div class="col-6">
          <button
            class="btn btn-secondary btn-block btn-sm"
            @click="$emit('close')"
          >
            <i class="fas fa-times pr-2" />
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="col-6">
          <button
            data-test="save-client-details-button"
            class="btn btn-primary btn-block btn-sm"
            :disabled="pending"
            @click="submit"
          >
            <i class="fas fa-check pr-2" />
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    variant: Object,
    productId: String,
  },
  data: () => ({
    name: '',
    description: '',
    tags: [],
    pending: false,
  }),
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
  },
  methods: {
    ...mapActions('shop', [
      'addProductVariant',
      'updateProductVariant',
    ]),
    removeTag(t, index) {
      this.tags = this.tags.filter((x, i) => i !== index);
    },
    addTag() {
      this.tags.push('');
      this.$nextTick(() => {
        const index = this.tags.length - 1;
        const ref = this.$refs[`tag-${index}`][0];
        if (ref) {
          ref.focus();
        }
      });
    },
    sendUpdate() {
      if (this.variant) {
        return this.updateProductVariant({
          params: {
            productId: this.productId,
            variantId: this.variant.variantId,
            catalogId: this.catalogDetails.id,
          },
          data: {
            name: this.name,
            code: this.code,
            description: this.description,
            tags: this.tags,
          },
        });
      }
      return this.addProductVariant({
        params: {
          productId: this.productId,
          catalogId: this.catalogDetails.id,
        },
        data: {
          name: this.name,
          description: this.description,
          tags: this.tags,
        },
      });
    },
    submit() {
      this.pending = true;
      this.sendUpdate()
        .then(() => {
          this.$emit('close');
          this.$emit('refresh');
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    if (this.variant) {
      this.name = this.variant.name;
      this.code = this.variant.code;
      this.description = this.variant.description;
      this.tags = this.variant.tags.slice();
    }
  },
};
</script>

<style lang="scss" scoped>
.form-cell {
  padding: 5px;
}

.product-remove {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  margin-left: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: rgba(#333, 0.2);
  }
}
</style>
