<template>
  <div
    class="h-100 position-relative"
  >
    <BModal
      v-model="catalogForm"
      hide-footer
      hide-header
      size="lg"
    >
      <CatalogForm
        :catalog="catalogDetails"
        @close="catalogForm = false"
        @refresh="request"
      />
    </BModal>

    <template v-if="catalogDetails">
      <div class="d-flex pt-2 catalog-header">
        <div
          class="secondary-icon mr-3"
          @click="$router.push(`/payee/${payeeId}/shop/catalogs`)"
        >
          <i class="fa-solid fa-chevron-left" />
        </div>
        <div class="flex-grow-1 mr-3">
          <div class="catalog-name">
            <i
              :class="productCategory.icon"
              class="pr-1"
            />
            {{ catalogDetails.name }}
          </div>
          <div class="catalog-type">
            <span>
              <i
                class="mr-1"
                :class="orderSchema.icon"
              />
              {{ orderSchema.text }}
            </span>
          </div>
        </div>
        <div class="category">
          <label class="mb-0">
            {{ $t('bill.category') }}:
          </label>
          <CategoryInfo
            :category-id="catalogDetails.billingCategoryId"
            class="category"
          />
        </div>
        <div
          class="category"
          style="margin-left: 20px"
        >
          <label class="mb-0">
            {{ $t('payment.accountNumber') }}:
          </label>
          <div>
            {{ bankAccountName }}
          </div>
        </div>
        <div class="ml-4 mt-1">
          <i
            v-tippy
            class="fas fa-pen secondary-icon smaller-icon"
            :content="$t('general.edit')"
            @click="catalogForm = true"
          />
        </div>
      </div>

      <div class="page-title-wrapper">
        <RouterLink
          :to="`/payee/${payeeId}/shop/${catalogId}/orders`"
          class="page-title"
          :class="{ active: page === 'orders' }"
        >
          {{ $t('shop.orders') }}
        </RouterLink>
        <RouterLink
          v-if="catalogId !== '00000000-0000-0000-0000-000000000000'"
          :to="`/payee/${payeeId}/shop/${catalogId}/offer`"
          class="page-title ml-4"
          :class="{ active: page === 'offer' }"
        >
          {{ $t('shop.offer') }}
        </RouterLink>
        <RouterLink
          v-else
          :to="`/payee/${payeeId}/shop/${catalogId}/offer_v1`"
          class="page-title ml-4"
          :class="{ active: page === 'offer' }"
        >
          {{ $t('shop.offer') }}
        </RouterLink>

        <RouterLink
          v-if="catalogDetails
            && catalogDetails.orderSchema === 'Daily'
            && catalogId !== '00000000-0000-0000-0000-000000000000'"
          :to="`/payee/${payeeId}/shop/${catalogId}/calendar`"
          class="page-title ml-4"
          :class="{ active: page === 'calendar' }"
        >
          {{ $t('shop.calendar') }}
        </RouterLink>
        <RouterLink
          v-if="catalogId === '00000000-0000-0000-0000-000000000000'"
          :to="`/payee/${payeeId}/shop/${catalogId}/sets_v1`"
          class="page-title ml-4"
          :class="{ active: page === 'sets' }"
        >
          {{ $t('shop.productSets') }}
        </RouterLink>
        <RouterLink
          :to="`/payee/${payeeId}/shop/${catalogId}/products`"
          class="page-title ml-4"
          :class="{ active: page === 'products' }"
        >
          {{ $t('shop.products') }}
        </RouterLink>
        <RouterLink
          v-if="catalogId === '00000000-0000-0000-0000-000000000000'"
          :to="`/payee/${payeeId}/shop/${catalogId}/reports`"
          class="page-title ml-4"
          :class="{ active: page === 'reports' }"
        >
          {{ $t('menu.reports') }}
        </RouterLink>
      </div>

      <!-- <RouterLink
        v-if="page === 'orders'"
        class="light-button"
        :to="`/payee/${payeeId}/order/today`"
        target="_blank"
      >
        <div
          v-tippy
          :content="$t('shop.screenForDelivery')"
          style="right: 30px"
          @click="$router.push()"
        >
          <i class="fa-solid fa-person-carry-box secondary-icon" />
        </div>
      </RouterLink> -->

      <RouterView
        @page="page = $event"
        @refresh="request"
      />
    </template>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import { accountNumber } from '@/utils/filters';
import { mapActions, mapGetters } from 'vuex';
import CatalogForm from './catalogs/CatalogForm';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    page: 'shop',
    catalogForm: false,
  }),
  components: {
    CategoryInfo,
    CatalogForm,
  },
  computed: {
    ...mapGetters('shop', [
      'productCategories',
      'orderSchemas',
      'catalogDetails',
    ]),
    ...mapGetters([
      'payeeId',
      'payee',
    ]),
    orderSchema() {
      return this.orderSchemas
        .find((x) => x.key === this.catalogDetails.orderSchema);
    },
    productCategory() {
      return this.productCategories
        .find((x) => x.key === this.catalogDetails.productCategory);
    },
    bankAccountName() {
      const name = (this.payee?.bankAccounts || [])
        .find((x) => x.accountNumber.toString() === (this.catalogDetails.accountNumber || '').toString())?.name;

      if (!name) {
        return accountNumber(this.catalogDetails.accountNumber);
      }

      return name;
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProducts',
      'getShopCatalog',
    ]),
    request() {
      this.getProducts({
        params: {
          catalogId: this.catalogId,
        },
      });

      this.getShopCatalog({
        params: {
          catalogId: this.catalogId,
        },
      })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'shop');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .catalog-name {
    font-weight: 500;
    font-size: 18px;
    min-width: 400px;
    margin-top: 2px;
  }

  .catalog-type {
    color: #666;
    font-size: 0.7rem;
  }

  .category {
    font-size: 12px;
    color: #888;

    label {
      font-size: 0.7rem;
    }
  }

  .catalog-header {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
  }

  .light-button {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  .shop-baner {
    padding-top: 50px;
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
    color: #aaa;

    i {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
</style>
