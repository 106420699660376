<template>
  <div class="position-relative">
    <div class="title mb-2">
      {{ catalog ? $t('shop.catalog.edit') : $t('shop.catalog.add') }}
    </div>
    <div class="form-cell">
      <label>{{ $t('shop.catalog.name') }}</label>
      <div>
        <BFormInput
          v-model="name"
          :placeholder="$t('shop.catalog.name')"
        />
      </div>
    </div>
    <div
      v-if="!catalog"
      style="max-width: 500px"
      class="form-cell"
    >
      <label>{{ $t('shop.catalog.orderSchema') }}</label>
      <div>
        <BoxSelect
          :selected.sync="type"
          :fields="orderSchemas"
        />
      </div>
    </div>
    <div
      v-if="!catalog"
      style="max-width: 500px"
      class="form-cell"
    >
      <label>{{ $t('shop.productCategory') }}</label>
      <div>
        <BoxSelect
          :selected.sync="productCategory"
          :fields="productCategories"
        />
      </div>
    </div>
    <div class="form-cell">
      <label>{{ $t('bill.category') }}</label>
      <div>
        <CategoryDropdown
          :selected="billingCategoryId"
          style="max-width: 300px"
          @update:selected="billingCategoryId = $event"
        />
      </div>
    </div>
    <div class="form-cell">
      <label>{{ $t('payment.accountNumber') }}</label>
      <div>
        <BFormSelect
          v-model="accountNumber"
          :state="sortedAccounts.length === 0 ? false : null"
          :options="sortedAccounts"
          style="font-size: 13px; max-width: 300px"
        />
      </div>
    </div>
    <div
      v-if="!catalog || catalog.id !== '00000000-0000-0000-0000-000000000000'"
      class="form-cell"
    >
      <label>{{ $t('shop.payTo') }}</label>
      <div>
        <TimeLimitInput
          :label="$t('shop.payTo')"
          :value.sync="payTo"
          :single-order-schema="type === 'Single'"
          default-reference="ListingMonthEnd|0.09:00:00.false"
        />
      </div>
    </div>
    <div
      v-if="!catalog || catalog.id !== '00000000-0000-0000-0000-000000000000'"
      class="mt-3"
    >
      <label class="mr-3 small">
        <i class="fa-solid fa-code mr-1" />
        {{ $t('shop.availabilityRule') }}:
      </label>
      <div class="pr-2 flex-grow-1">
        <BFormTextarea
          v-model="accessRule"
          size="sm"
          :rows="1"
          class="money-font"
        />
      </div>
    </div>
    <hr>
    <div>
      <div
        class="row"
        style="width: 400px; margin-left: auto; margin-right: 0"
      >
        <div class="col-6">
          <button
            class="btn btn-secondary btn-block btn-sm"
            @click="$emit('close')"
          >
            <i class="fas fa-times pr-2" />
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="col-6">
          <button
            data-test="save-client-details-button"
            class="btn btn-primary btn-block btn-sm"
            :disabled="pending"
            @click="submit"
          >
            <i class="fas fa-check pr-2" />
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryDropdown from '@/components/bills/form/CategoryDropdown';
import BoxSelect from '@/components/BoxSelect';
import { mapActions, mapGetters } from 'vuex';
import TimeLimitInput from './TimeLimitInput';

export default {
  props: {
    catalog: Object,
  },
  data: () => ({
    name: '',
    payTo: '',
    accountNumber: null,
    billingCategoryId: null,
    accessRule: '',
    type: 'Single',
    productCategory: 'Catering',
    pending: false,
  }),
  components: {
    CategoryDropdown,
    TimeLimitInput,
    BoxSelect,
  },
  computed: {
    ...mapGetters([
      'payee',
      'bankAccounts',
    ]),
    ...mapGetters('shop', [
      'orderSchemas',
      'singleOrderSchema',
      'productCategories',
    ]),
    sortedAccounts() {
      return this.bankAccounts
        .map((x) => ({
          text: x.name,
          value: x.accountNumber,
        }));
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateShopCatalog',
      'createShopCatalog',
    ]),
    submit() {
      if (this.catalog) {
        this.updateCatalog();
      } else {
        this.createCatalog();
      }
    },
    createCatalog() {
      this.createShopCatalog({
        data: {
          name: this.name,
          orderSchema: this.type,
          productCategory: this.productCategory,
          accountNumber: this.accountNumber,
          billingCategoryId: this.billingCategoryId,
          accessRule: this.accessRule,
          payTo: this.payTo,
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');

          this.pending = false;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    updateCatalog() {
      this.updateShopCatalog({
        params: {
          id: this.catalog.id,
        },
        data: {
          name: this.name,
          accountNumber: this.accountNumber,
          billingCategoryId: this.billingCategoryId,
          accessRule: this.accessRule,
          payTo: this.payTo,
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');

          this.pending = false;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    if (this.catalog) {
      this.name = this.catalog.name;
      this.accessRule = this.catalog.accessRule;
      this.accountNumber = this.catalog.accountNumber;
      this.billingCategoryId = this.catalog.billingCategoryId;
      this.payTo = this.catalog.payTo;
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  font-weight: 500;
}
</style>
