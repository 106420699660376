<template>
  <div
    class="catalog-row d-flex align-items-center"
    :class="{ selected }"
    @click="$emit('edit')"
  >
    <div class="mr-3 ml-1">
      <i
        :class="productCategory.icon"
      />
    </div>
    <div>
      <div class="catalog-name">
        {{ catalog.name }}
      </div>
      <div class="catalog-type">
        <span>
          <i
            class="mr-1"
            :class="orderSchema.icon"
          />
          {{ orderSchema.text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    catalog: Object,
    selected: Boolean,
  },
  data: () => ({
    expanded: true,
  }),
  computed: {
    ...mapGetters('shop', [
      'orderSchemas',
      'productCategories',
    ]),
    orderSchema() {
      return this.orderSchemas
        .find((x) => x.key === this.catalog.orderSchema);
    },
    productCategory() {
      return this.productCategories
        .find((x) => x.key === this.catalog.productCategory);
    },
  },
};
</script>

<style lang="scss" scoped>
.catalog-row {
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid rgba(100, 100, 100, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }

  &.selected {
    background-color: rgba($blue, 0.1);
  }

  &.caret {
    width: 48px;
    text-align: center;
  }
}

.catalog-type {
  color: #666;
  font-size: 0.7rem;
}

.catalog-name {
  width: 300px;
  font-weight: 500;
}
</style>
