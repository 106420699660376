<template>
  <div class="d-flex h-100">
    <div class="calendar-wrapper">
      <Calendar
        ref="calendar"
        :available-days="availableDays"
        :selected-days="[]"
        :day-status="dayStatus"
        :init-at="initDate"
        compressed
        @dates="dates = $event"
        @highlighted="highlighted = $event"
      />
    </div>
    <div class="flex-grow-1">
      <div
        class="d-flex align-items-center justify-content-between"
      >
        <div class="d-flex pl-3">
          <SearchBox
            v-model="search"
            lazy
            class="mr-3"
          />
          <PayersGroupFilterDropdown
            :selected.sync="groupCode"
            class="mr-3"
            hide-edit
          />
        </div>
        <div class="d-flex">
          <button
            class="btn btn-sm btn-primary mr-2"
            @click="$router.push(`/payee/${payeeId}/shop/${catalogDetails.id}/import`)"
          >
            <i class="fas fa-upload pr-2" />
            {{ $t('menu.import') }}
          </button>
        </div>
      </div>
      <hr class="mb-1">
      <div
        class="table-header-row d-none d-md-flex w-100"
      >
        <div
          class="pl-2 flex-grow-1"
          style="margin-left: 0.5rem"
        >
          <label class="mb-0 column-header">
            {{ $t('general.name') }}
          </label>
        </div>
        <div
          class="pl-2"
          style="width: 200px"
        >
          <label class="mb-0 column-header">
            {{ $t('groups.groups') }}
          </label>
        </div>
        <div
          class="pl-2"
          style="width: 150px"
        >
          <label class="mb-0 column-header">
            {{ $t('shop.price') }}
          </label>
        </div>
      </div>
      <div class="scroll-sets">
        <div
          v-if="pending"
          class="mt-3"
        >
          <Loader />
        </div>
        <div
          v-for="set in availableSets"
          :key="set.availabilityId"
          class="table-row"
          :class="{ selected: selectedAvailabilityId === set.availabilityId }"
          @mouseover="highlight = set.availabilityId"
          @mouseleave="highlight = null"
          @click="select(set)"
        >
          <div class="cell d-flex w-100 align-items-center">
            <div
              class="pl-2 flex-grow-1"
            >
              <i class="fas fa-fork-knife product-icon" />
              {{ set.productSetName }}
              <div class="font-weight-bold text-secondary small">
                {{ set.name }}
              </div>
            </div>
            <div
              class="pl-2 small d-flex flex-wrap"
              style="width: 200px;"
            >
              <div
                v-for="g in set.groups"
                :key="g"
                class="group-label mr-1 mt-1 text-truncate"
              >
                {{ g }}
              </div>
            </div>
            <div
              class="money-font pl-2"
              style="width: 150px"
            >
              {{ formatCurrency(set.minPrice, set.currency) }}
              <span v-if="set.maxPrice != set.minPrice">
                - {{ formatCurrency(set.maxPrice, set.currency) }}
              </span>
            </div>
            <div class="pl-2 time-info align-self-end text-secondary">
              <div class="time-label">
                {{ $t('shop.orderFrom') }}:
                <span class="value">
                  {{ set.orderFrom }}
                </span>
              </div>
              <div class="time-label">
                {{ $t('shop.orderTo') }}:
                <span class="value">
                  {{ set.orderTo }}
                </span>
              </div>
              <div class="time-label">
                {{ $t('shop.cancelTo') }}:
                <span class="value">
                  {{ set.cancelTo }}
                </span>
              </div>
              <div class="time-label">
                {{ $t('shop.payTo') }}:
                <span class="value">
                  {{ set.payTo }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BModal
      v-model="modal"
      hide-footer
      hide-header
      size="lg"
    >
      <div
        v-if="selectedSet"
        class="font-weight-bold"
        style="font-size: 1.3rem"
      >
        <i class="fas fa-fork-knife product-icon pr-1" />
        {{ selectedSet.productSetName }}
      </div>
      <hr>
      <AvailabilityDetails
        v-if="availability"
        :availability="availability"
        no-scroll
        @removed="availabilityRemoved()"
      />
      <div v-else>
        <Loader />
      </div>
    </BModal>
  </div>
</template>

<script>
import PayersGroupFilterDropdown from '@/components/payers/PayersGroupFilterDropdown';
import AvailabilityDetails from '@/components/shop/AvailabilityDetails';
import Calendar from '@/modules/payer/components/shop/Calendar';
import createSearch from '@/utils/search';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    search: '',
    highlighted: [],
    dates: {},
    productSets: null,
    groupCode: '#all',
    initDate: null,
    offer: {},
    highlight: null,
    pending: false,
    modal: false,
    availability: null,
    selectedAvailabilityId: null,
    selectedProductSetId: null,
  }),
  components: {
    PayersGroupFilterDropdown,
    AvailabilityDetails,
    Calendar,
  },
  computed: {
    ...mapGetters([
      'payeeId',
      'groups',
      'formatCurrency',
    ]),
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    selectedSet() {
      return this.availableSets.find((x) => x.availabilityId === this.selectedAvailabilityId);
    },
    productSetsInTimeRange() {
      if (!this.offer?.availableProductSets) return [];

      const from = moment(this.dates.from, 'YYYY-MM-DD');
      const to = moment(this.dates.to, 'YYYY-MM-DD');

      return this.offer.availableProductSets
        .filter((x) => x.days.some((d) => {
          const m = moment(d, 'YYYY-MM-DD');
          return m.isSameOrAfter(from) && m.isBefore(to);
        }));
    },
    availableSets() {
      if (this.pending) return [];
      if (!this.offer?.availableProductSets || !this.productSets) return [];
      const search = createSearch(this.search);

      return this.productSetsInTimeRange
        .filter((x) => this.highlighted.length === 0 || x.days.some((d) => this.highlighted.includes(d)))
        .map((x) => {
          const productSet = this.productSets.find((s) => s.id === x.productSetId);

          return {
            ...x,
            productSetName: productSet?.name ?? '?',
            groups: x.groupCodes
              .map((c) => (c === '#all'
                ? this.$t('company.all')
                : this.groups.find((g) => c === g.code)?.path))
              .filter((y) => y),
          };
        })
        .filter((x) => search(x.productSetName) || search(x.name))
        .sort((a, b) => a.productSetName.localeCompare(b.productSetName));
    },
    availableDays() {
      return Object.keys(this.dayStatus);
    },
    dayStatus() {
      if (!this.offer?.availableProductSets) return {};
      if (!this.dates) return {};

      const from = moment(this.dates.from, 'YYYY-MM-DD');
      const to = moment(this.dates.to, 'YYYY-MM-DD');

      return this.productSetsInTimeRange
        .reduce((acc, curr) => {
          curr.days.forEach((d) => {
            const m = moment(d, 'YYYY-MM-DD');
            if (m.isBefore(from) || m.isSameOrAfter(to)) {
              return;
            }

            if (!acc[d]) {
              acc[d] = {
                paid: 0,
                waiting: 0,
              };
            }
            acc[d].paid += 1;

            if (this.highlight) {
              if (this.highlight && curr.availabilityId === this.highlight) {
                acc[d].waiting += 1;
              }
            }
          });

          return acc;
        }, {});
    },
  },
  watch: {
    groupCode() {
      this.request();
      this.updateQuery();
    },
    dates() {
      this.request();
      this.updateQuery();
    },
    search() {
      this.updateQuery();
    },
    selectedAvailabilityId() {
      this.requestAvailability();
    },
    modal(v) {
      if (!v) {
        this.selectedAvailabilityId = null;
        this.selectedProductSetId = null;
      }
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProductSetAvailability',
      'getPayeeOffer',
      'getProductSets',
      'getProducts',
    ]),
    availabilityRemoved() {
      this.offer.availableProductSets = this.offer.availableProductSets
        .filter((x) => x.availabilityId !== this.availability.id);

      this.modal = false;
    },
    updateQuery() {
      this.$router.replace({
        query: {
          groupCode: this.groupCode === '#all' ? undefined : this.groupCode,
          date: this.dates?.from || undefined,
          search: this.search || undefined,
        },
      });
    },
    select(set) {
      this.selectedAvailabilityId = set.availabilityId;
      this.selectedProductSetId = set.productSetId;
      this.modal = true;
    },
    requestAvailability() {
      if (!this.selectedAvailabilityId) {
        return;
      }

      this.pendingAvailability = true;
      this.getProductSetAvailability({
        params: {
          availabilityId: this.selectedAvailabilityId,
          query: {
            productSetId: this.selectedProductSetId,
          },
        },
      })
        .then(({ data }) => {
          this.availability = data;
        })
        .finally(() => {
          this.pendingAvailability = false;
        });
    },
    request() {
      if (!this.dates) {
        return;
      }

      this.pending = true;
      this.getPayeeOffer({
        params: {
          query: {
            from: this.dates.from,
            to: this.dates.to,
            groupCode: this.groupCode === '#all'
              ? ''
              : this.groupCode,
          },
        },
      })
        .then(({ data }) => {
          this.offer = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'offer');

    if (this.$route.query?.groupCode) {
      this.groupCode = this.$route.query.groupCode;
    }
    if (this.$route.query?.search) {
      this.search = this.$route.query.search;
    }
    if (this.$route.query?.date) {
      this.initDate = moment(this.$route.query.date, 'YYYY-MM-DD').format();
    }

    this.getProducts({
      params: {
        catalogId: this.catalogId,
      },
    });
    this.getProductSets()
      .then(({ data }) => {
        this.productSets = data;
      });
  },
};
</script>

<style lang="scss" scoped>

  .table-row {
    flex-direction: column;
    align-items: flex-start;

    &:hover {
      background-color: rgba($orange, 0.2);
    }

    &.selected {
      background-color: rgba($blue, 0.5);
    }
  }

  .group-label {
    white-space: nowrap;
    overflow: hidden;
  }

  .time-info {
    width: 180px;
    min-width: 180px;
  }

  .scroll-sets {
    overflow: auto;
    height: calc(100vh - 215px);
    padding-bottom: 40px;
  }

  .time-label {
    font-size: 0.8rem;
    margin-right: 10px;
    padding-left: 10px;
    width: 160px;

    .value {
      font-weight: 500;
    }
  }

  .product-icon {
    width: 25px;
    color: #9a9a9a;
  }

  .calendar-wrapper {
    max-width: 500px;
    width: 40%;
    padding: 20px;
    border-right: 2px solid #eee;
  }
</style>
