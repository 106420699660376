<template>
  <div class="d-flex align-items-center w-100 node-selected-row">
    <div>
      <div
        class="secondary-icon mx-3"
        @click="$emit('remove')"
      >
        <i class="fa-solid fa-xmark" />
      </div>
    </div>
    <div class="flex-grow-1">
      <div class="font-weight-bold">
        {{ node.name }}
      </div>
    </div>
    <div
      v-if="node.accessRule"
      v-tippy="$t('shop.availabilityRule')"
      class="d-flex align-items-center mt-1"
    >
      <div
        class="d-flex align-items-center group-price px-2 first text-secondary"
      >
        <div class="mb-0 mr-1 rule-symbol">
          <i class="fa-solid fa-code mx-1" />
        </div>
        <div class="small money-font">
          {{ node.accessRule }}
        </div>
      </div>
    </div>
    <div
      v-if="node.deliveryPlan"
      v-tippy="$t('shop.availabilityRule')"
      class="d-flex align-items-center mt-1"
    >
      <div
        class="d-flex align-items-center group-price px-2 first text-secondary"
      >
        <div class="mb-0 mr-1 rule-symbol">
          <i class="fa-solid fa-truck-utensils mx-1" />
        </div>
        <div class="small money-font">
          {{ node.deliveryPlan }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    node: Object,
  },
};
</script>

<style lang="scss" scoped>
.node-selected-row {
  padding: 10px 0px;
  border-bottom: 1px solid rgb(239, 239, 239);
}

.rule-symbol {
  align-self: stretch;
  height: 100%;
}

.group-price {
  padding: 5px;
  border: 1px solid #eee;
  border-top: none;

  &.first {
    border-top: 1px solid #eee;
  }
}
</style>
