<template>
  <div
    v-if="!subscription && !reportType"
    class="position-relative"
  >
    <div
      class="close-button"
      @click="$emit('close')"
    >
      <i class="fas fa-chevron-left secondary-icon" />
    </div>
    <div class="h5 font-weight-bold text-center">
      {{ $t('shop.reports.selectReportType') }}:
    </div>

    <div class="report-type-wrapper">
      <div
        v-for="t in ordersReportTypes"
        :key="t.type"
        class="report-type"
        @click="reportType = t.type"
      >
        <i :class="t.icon" />
        {{ t.text }}
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex align-items-center">
      <div
        class="mr-2"
        @click="subscription == null ? reportType = null : $emit('close')"
      >
        <i class="fas fa-chevron-left secondary-icon" />
      </div>
      <div
        v-if="selectedReportType"
        class="font-weight-bold"
      >
        <i :class="selectedReportType.icon" />
        {{ selectedReportType.text }}
      </div>
    </div>
    <hr class="mb-2">
    <DailyOrdersForm
      v-if="reportType === 'DailyOrders'"
      :subscription="subscription"
      @update="config = $event"
    />
    <DailyProductSetSummary
      v-else-if="reportType === 'DailyProductSetSummary'"
      :subscription="subscription"
      @update="config = $event"
    />
    <hr>
    <div
      v-if="error"
      class="text-danger font-weight-bold text-center mb-4"
    >
      {{ $t('error.error') }}!
    </div>
    <div class="d-flex justify-content-between">
      <RemoveButton
        :pending="pending"
        @confirm="remove"
      />

      <SaveButton
        class="flex-grow-1"
        :pending="pending"
        @cancel="$emit('close')"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import SaveButton from '@/components/SaveButton';
import RemoveButton from '@/components/utils/RemoveButton';
import { mapActions, mapGetters } from 'vuex';
import DailyOrdersForm from './types/DailyOrdersForm';
import DailyProductSetSummary from './types/DailyProductSetSummary';

export default {
  props: {
    subscription: Object,
  },
  data: () => ({
    pending: false,
    error: false,
    reportType: '',
    config: '{}',
  }),
  components: {
    DailyProductSetSummary,
    DailyOrdersForm,
    RemoveButton,
    SaveButton,
  },
  computed: {
    ...mapGetters('shop', ['ordersReportTypes']),
    selectedReportType() {
      return this.ordersReportTypes.find((x) => x.type === this.reportType);
    },
  },
  methods: {
    ...mapActions('shop', [
      'addReportSubscription',
      'updateReportSubscription',
      'removeReportSubscription',
    ]),
    remove() {
      this.removeReportSubscription({
        params: {
          id: this.subscription.id,
        },
      })
        .then(({ data }) => {
          this.$emit('updated', data);
          this.$emit('close');
        });
    },
    save() {
      this.pending = true;
      if (this.subscription) {
        this.updateReportSubscription({
          params: {
            id: this.subscription.id,
          },
          data: {
            config: JSON.stringify(this.config),
          },
        })
          .then(({ data }) => {
            this.$emit('updated', data);
            this.$emit('close');
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            this.pending = false;
          });
      } else {
        this.addReportSubscription({
          data: {
            type: this.reportType,
            config: JSON.stringify(this.config),
          },
        })
          .catch(() => {
            this.error = true;
          })
          .then(({ data }) => {
            this.$emit('updated', data);
            this.$emit('close');
          })
          .finally(() => {
            this.pending = false;
          });
      }
    },
  },
  created() {
    if (this.subscription) {
      this.reportType = this.subscription.type;
    }
  },
};
</script>

<style lang="scss" scoped>
  .group-row {
    border-bottom: 1px solid #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    &:hover {
      background-color: #efefef;
    }
  }

  .close-button {
    position: absolute;
  }

  .report-type-wrapper {
    max-width: 600px;
    margin: auto;
    display: flex;
  }

  .report-type {
    border: 2px solid #eee;
    border-radius: 10px;
    width: 50%;
    margin: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    font-weight: normal;
    text-align: center;
    cursor: pointer;

    &:hover {
      border-color: rgba($blue, 0.6);
      background-color: rgba($blue, 0.05);
    }

    i {
      display: block;
      text-align: center;
      font-size: 30px;
      padding: 10px;
      margin-bottom: 10px;
    }
  }

  .draggable {
    border: 1px solid transparent;
    border-radius: 10px;
    min-height: 50px;
    background-color: #f8f8f8;
    padding: 5px;
    margin-top: 5px;
    min-height: 200px;
    padding-bottom: 40px;
  }

  .draggable.moving {
    border: 1px solid #aaa;
  }

  .moving * {
    cursor: grabbing;
  }

.column {
  padding: 0.3rem 0.6rem;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #eee;
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
}
</style>
