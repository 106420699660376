<template>
  <div class="d-flex align-items-center">
    <div style="width: 250px">
      <BFormSelect
        v-model="type"
        :options="timeReferenceTypes"
      />
    </div>
    <Tippy
      class="ml-3"
      interactive
      theme="light"
      trigger="click"
      append-to="parent"
    >
      <template #trigger>
        <div
          class="d-flex align-items-center"
          style="height: 38px; width: 140px"
        >
          <div class="secondary-icon">
            <i class="fas fa-sliders" />
          </div>
          <div class="small ml-2">
            <div>
              {{ hour }}
            </div>
            <div v-if="dayOffset != 0">
              <span v-if="dayOffset < 0">{{ dayOffset }} {{ $t('shop.timeReference.daysBefore') }}</span>
              <span v-if="dayOffset > 0">{{ dayOffset }} {{ $t('shop.timeReference.daysAfter') }}</span>
            </div>
            <div v-if="skipWeekend">
              {{ $t('report.time.skipWeekend') }}
            </div>
          </div>
        </div>
      </template>
      <DeliveryTimeSelect
        :hour.sync="hour"
        :skip-weekend.sync="skipWeekend"
        :day-offset.sync="dayOffset"
        :day-offset-title="$t('shop.timeReference.dayOffset')"
        vertical
      />
    </Tippy>
  </div>
</template>

<script>
import DeliveryTimeSelect from '@/components/shop/report/DeliveryTimeSelect';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: String,
  },
  data: () => ({
    hour: '09:00',
    skipWeekend: false,
    dayOffset: 0,
    type: 'ListingMonthEnd',
  }),
  components: {
    DeliveryTimeSelect,
  },
  computed: {
    ...mapGetters('shop', [
      'timeReferenceTypes',
      'parseTimeReference',
    ]),
    rule() {
      return `${this.type}|${this.dayOffset}.${this.hour}:00.${this.skipWeekend}`;
    },
  },
  watch: {
    rule(v) {
      this.$emit('update:value', v);
    },
  },
  created() {
    const parsed = this.parseTimeReference(this.value);
    if (parsed) {
      this.type = parsed.type;
      this.dayOffset = parsed.dayOffset;
      this.skipWeekend = parsed.skipWeekend;
      this.hour = parsed.hour;
    }
  },
};
</script>

<style lang="scss" scoped>
.time-reference-box {
  border: 1px solid #efefef;
  background-color: rgba(#efefef, 0.1);
  padding: 10px;
  border-radius: 10px;
}
</style>
