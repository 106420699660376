<template>
  <div>
    <Draggable
      v-model="orderedChildren"
      class="draggable"
      ghost-class="ghost"
      handle=".handle"
      animation="200"
      :class="{ moving }"
      @start="moving = true"
      @end="moving = false"
    >
      <div
        v-for="p in orderedChildren"
        :key="p.id"
      >
        <div
          class="client-property d-flex"
          :class="{ selected: selected === p.id }"
          @click="toggle(p.id)"
        >
          <i class="fas fa-grip-dots-vertical handle mr-2" />
          <span class="text-truncate">
            {{ p.name }}
          </span>
        </div>

        <SubCatalogsList
          class="ml-3"
          :list="list"
          :toggle="toggle"
          :selected="selected"
          :root-id="p.id"
          @orderUpdated="$emit('orderUpdated', $event)"
        />
      </div>
    </Draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'SubCatalogsList',
  props: {
    list: Array,
    toggle: Function,
    selected: [String, Number],
    rootId: String,
  },
  data: () => ({
    order: {},
    moving: false,
  }),
  components: {
    Draggable,
  },
  computed: {
    orderedChildren: {
      get() {
        return this.children
          .slice()
          .sort((a, b) => this.order[a.id] - this.order[b.id]);
      },
      set(v) {
        this.order = {};
        v.forEach((x, i) => {
          this.$set(this.order, x.id, i);
        });
        this.$emit('orderUpdated', { parentId: this.rootId, order: this.order });
      },
    },
    orderModified() {
      return this.children
        .some((x) => this.order[x.id] !== x.ordinal);
    },
    children() {
      return this.list.filter((x) => x.parentCode === this.rootId);
    },
  },
  created() {
    this.list.forEach((x) => {
      this.$set(this.order, x.id, x.ordinal);
    });
  },
};

</script>

<style lang="scss" scoped>
  .client-property {
    padding: 0.3rem 0.6rem;
    border-radius: 10px;
    border: 2px solid #eee;
    margin-top: 6px;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    transition: border-color 300ms, margin-right 300ms;

    &:hover {
      border-color: #999;
    }
    &.selected {
      margin-right: -20px;
      background-color: rgba($blue, 0.1);
      border-color: $darkblue;
    }
  }
</style>
