import { render, staticRenderFns } from "./TimeLimitInput.vue?vue&type=template&id=1f475184&scoped=true&"
import script from "./TimeLimitInput.vue?vue&type=script&lang=js&"
export * from "./TimeLimitInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f475184",
  null
  
)

export default component.exports