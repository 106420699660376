<template>
  <div class="page-cart mb-2 pt-4">
    <div
      class="d-flex justify-content-between align-items-center pb-3"
    >
      <div class="d-flex align-items-center px-2">
        <div class="product-icon">
          <i
            class="fas fa-fork-knife"
          />
        </div>
        <div
          class="product-name"
        >
          {{ productSet.name }}
        </div>
      </div>
      <div
        v-if="!selectedAvailabilityId"
        class="d-flex"
      >
        <div class="pr-2">
          <RemoveButton
            :remove-warning="$t('shop.productSetRemoveWarning')"
            @confirm="removeSet"
          >
            <i
              v-tippy
              class="fas fa-trash secondary-icon"
              :content="$t('general.remove')"
            />
          </RemoveButton>
        </div>
        <div class="pl-2">
          <i
            v-tippy
            class="fas fa-pen secondary-icon smaller-icon"
            :content="$t('general.edit')"
            @click="$emit('edit', productSet)"
          />
        </div>
      </div>
    </div>
    <template v-if="!selectedAvailabilityId">
      <div
        v-if="productSet.description"
        class="row no-gutters align-items-center mt-2"
      >
        <div class="col-5 set-info">
          <span class="pr-2 mb-0 small text-secondary">
            <i class="fas fa-file-lines pr-1" />
            {{ $t('general.description') }}
          </span>
        </div>
        <div class="small col-7">
          {{ productSet.description }}
        </div>
      </div>

      <div
        v-if="showType"
        class="row no-gutters align-items-center mt-2"
      >
        <div class="col-5 set-info">
          <span class="pr-2 mb-0 small text-secondary">
            <i class="fa-regular fa-rectangle-history" />
            {{ $t('shop.settings.productSetType') }}
          </span>
        </div>
        <div class="small col-7">
          {{ productSet.typeName }}
        </div>
      </div>
    </template>

    <template v-if="!selectedAvailabilityId">
      <h5 class="payer-section mb-0 mt-3">
        {{ $t('shop.availability') }}
      </h5>
      <hr class="mt-2">
      <div>
        <div
          v-for="a in availabilities"
          :key="a.id"
          class="availability-info-box d-flex align-items-center justify-content-between mb-1"
          @click="selectedAvailabilityId = a.id; requestAvailability()"
        >
          <div>
            <div
              v-if="a.name"
              class="small"
            >
              {{ a.name }}
            </div>
            <div
              v-if="a.firstDay && a.lastDay"
              class="font-weight-bold"
            >
              {{ formatDay(a.firstDay) }} - {{ formatDay(a.lastDay) }}
            </div>
          </div>

          <div
            v-if="a.minPrice != a.maxPrice"
            class="money-font"
          >
            {{ formatCurrency(a.minPrice, a.currency) }} - {{ formatCurrency(a.maxPrice, a.currency) }}
          </div>
          <div
            v-else-if="a.minPrice == a.maxPrice"
            class="money-font"
          >
            {{ formatCurrency(a.minPrice, a.currency) }}
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div
          class="secondary-icon bill-button"
          style="max-width: 250px; margin: auto"
          @click="addAvailability"
        >
          <i class="fas fa-add pr-2" />
          <span class="edit-details">
            {{ $t('shop.addAvailability') }}
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="selectedAvailabilityId = null"
        >
          ×
        </button>
      </div>
      <AvailabilityDetails
        v-if="!pendingAvailability && availability"
        :availability="availability"
        @removed="removed(availability)"
      />
      <div
        v-else-if="pendingAvailability"
        class="text-center"
      >
        <Loader />
      </div>
    </template>
  </div>
</template>

<script>
import RemoveButton from '@/components/utils/RemoveButton';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import AvailabilityDetails from './AvailabilityDetails';

export default {
  props: {
    productSet: Object,
    showType: Boolean,
  },
  data: () => ({
    focus: false,
    edit: false,
    availabilitiesPending: false,
    availabilities: [],
    pendingAvailability: false,
    selectedAvailabilityId: null,
    availability: null,
  }),
  components: {
    RemoveButton,
    AvailabilityDetails,
  },
  computed: {
    ...mapGetters(['payeeId', 'payee', 'formatCurrency']),
    bankAccountName() {
      if (!this.productSet || !this.payee) return '';
      return this.payee.bankAccounts
        .find((x) => x.accountNumber.toString() === (this.productSet.accountNumber || '').toString())?.name;
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProductSetAvailabilities',
      'getProductSetAvailability',
      'removeProductSet',
    ]),
    removed({ id }) {
      this.selectedAvailabilityId = null;
      this.availabilities = this.availabilities.filter((x) => x.id !== id);
    },
    formatDay(d) {
      return moment(d).format('DD MMM YYYY');
    },
    requestAvailability() {
      this.pendingAvailability = true;
      this.getProductSetAvailability({
        params: {
          availabilityId: this.selectedAvailabilityId,
          query: {
            productSetId: this.productSet.id,
          },
        },
      })
        .then(({ data }) => {
          this.availability = data;
        })
        .finally(() => {
          this.pendingAvailability = false;
        });
    },
    addAvailability() {
      this.$router.push({
        path: `/payee/${this.payeeId}/shop/availability`,
        query: {
          productSetId: this.productSet.id,
        },
      });
    },
    removeSet() {
      this.removeProductSet({
        params: {
          productSetId: this.productSet.id,
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');
        });
    },
    request() {
      this.availabilitiesPending = true;
      this.getProductSetAvailabilities({
        params: {
          query: {
            productSetId: this.productSet.id,
          },
        },
      })
        .then(({ data }) => {
          this.availabilities = data;
        })
        .finally(() => {
          this.availabilitiesPending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.availability-info-box {
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 15px;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba($blue, 0.2);
  }
}

.set-info {
  line-height: 1.1;
}

.close {
  font-size: 30px;
  position: relative;
  top: -5px;
}

.product-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

.payer-section {
  font-size: 16px;
  font-weight: 600 !important;
}

.product-icon {
  font-size: 25px;
  margin-right: 15px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 42px;
  background-color: #efefef;
}
</style>
