<template>
  <div>
    <div style="margin: 5px">
      <label>
        {{ $t('shop.subcatalog.name') }}
      </label>
      <BFormInput
        v-model="name"
        :placeholder="$t('shop.subcatalog.name')"
        size="sm"
      />
    </div>
    <div style="margin: 5px; margin-top: 15px">
      <label>
        {{ $t('shop.subcatalog.displayLayout') }}
      </label>
      <BFormSelect
        v-model="layout"
        :options="options"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
    subCatalog: Object,
    pending: Boolean,
  },
  data: () => ({
    name: '',
    layout: 0,
    error: false,
  }),
  computed: {
    ...mapGetters('shop', [
      'layoutOptions',
    ]),
    options() {
      return this.layoutOptions;
    },
    otherNodes() {
      return this.nodes
        .filter((x) => !this.node || x.nodeId !== this.node.nodeId)
        .map((x) => ({
          value: x.nodeId,
          text: this.getPath(x.nodeId),
        }))
        .concat([
          {
            value: '',
            text: '-',
          },
        ]);
    },
  },
  methods: {
    ...mapActions('shop', [
      'createShopSubCatalog',
      'updateShopSubCatalog',
      'deleteShopSubCatalog',
    ]),
    submit() {
      if (this.subCatalog) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.$emit('update:pending', true);
      this.error = false;
      this.updateShopSubCatalog({
        params: {
          catalogId: this.catalogId,
          subCatalogId: encodeURIComponent(this.subCatalog.subCatalogId),
        },
        data: {
          name: this.name,
          layoutRole: this.layout,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.$emit('update:pending', true);
      this.error = false;
      this.createShopSubCatalog({
        params: {
          catalogId: this.catalogId,
        },
        data: {
          name: this.name,
          layoutRole: this.layout,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.deleteShopSubCatalog({
        params: {
          subCatalogId: this.subCatalog.subCatalogId,
          catalogId: this.catalogId,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.subCatalog) {
      this.name = this.subCatalog.name;
      this.layout = this.subCatalog.layoutRole;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
