<template>
  <div
    class="d-flex align-items-end"
    :class="{ 'flex-column': vertical }"
  >
    <div :class="vertical ? 'my-2' : 'mx-3'">
      <div class="font-weight-bold text-left">
        {{ $t('shop.reports.hour') }}
      </div>
      <BFormInput
        :value="hour"
        type="text"
        placeholder="HH:mm"
        lazy-formatter
        :formatter="formatter"
        @update="$emit('update:hour', $event)"
      />
    </div>
    <div :class="vertical ? 'my-2' : 'mx-3'">
      <div
        v-if="!dayOffsetTitle"
        class="d-flex"
      >
        <div class="font-weight-bold">
          {{ $t('shop.reports.forDay') }}
        </div>
        <TooltipHelp
          class="pl-2"
          :width="500"
        >
          <div style="white-space: pre-wrap">
            {{ $t('shop.reports.forDayInfo') }}
          </div>
        </TooltipHelp>
      </div>
      <div v-else>
        <div class="font-weight-bold">
          {{ dayOffsetTitle }}
        </div>
      </div>
      <BFormInput
        :value="dayOffset"
        type="number"
        @update="$emit('update:dayOffset', $event)"
      />
    </div>
    <div :class="vertical ? 'my-2' : 'mx-3'">
      <SwitchWithDescription
        :title="$t('report.time.skipWeekend')"
        small
        class="mb-1"
        style="min-height: 44px; margin-top: 0"
        :value="skipWeekend"
        @switch="$emit('update:skipWeekend', !skipWeekend)"
      />
    </div>
  </div>
</template>

<script>
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';

export default {
  props: {
    hour: String,
    skipWeekend: Boolean,
    dayOffset: Number,
    vertical: Boolean,
    dayOffsetTitle: String,
  },
  components: {
    SwitchWithDescription,
  },
  methods: {
    formatter(value) {
      const parts = value.split(':');
      let hours; let
        minutes;

      if (parts.length < 2) {
        const numericValue = value.replace(/\D/g, '');
        if (!numericValue) {
          return '00:00';
        }
        hours = numericValue.length >= 2 ? numericValue.slice(0, 2) : '00';
        return `${hours}:00`;
      }

      hours = parts[0].replace(/\D/g, '').slice(0, 2).padStart(2, '0');
      minutes = parts[1].replace(/\D/g, '').slice(0, 2).padStart(2, '0');

      hours = parseInt(hours, 10);
      minutes = parseInt(minutes, 10);

      if (Number.isNaN(hours) || hours > 23) {
        hours = 0;
      }
      if (Number.isNaN(minutes) || minutes > 59) {
        minutes = 0;
      }

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    },
  },
};
</script>
