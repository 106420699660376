<template>
  <div style="margin: 5px; padding-top: 10px">
    <BFormInput
      v-model="name"
      :placeholder="$t('shop.settings.typeName')"
      size="sm"
    />

    <BFormCheckbox
      v-model="isDefault"
      :disabled="!!(type && type.isDefault)"
      style="line-height: 1.8; margin-top: 10px; margin-left: -8px"
    >
      {{ $t('settings.categories.default') }}
    </BFormCheckbox>

    <div
      v-if="error || errDefault"
      class="small text-danger mt-2"
    >
      <i class="fas fa-info-circle info-icon" />
      {{ error ? error : $t('error.error') }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    type: Object,
    pending: Boolean,
  },
  data: () => ({
    isDefault: false,
    isActive: true,
    name: '',
    error: null,
    errDefault: false,
  }),
  methods: {
    ...mapActions('shop', [
      'addProductSetType',
      'updateProductSetType',
      'removeProductSetType',
    ]),
    submit() {
      if (this.type) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.$emit('update:pending', true);
      this.updateProductSetType({
        params: {
          id: this.type.id,
        },
        data: {
          name: this.name,
          isDefault: this.isDefault,
        },
      })
        .then(({ data }) => {
          this.name = '';
          this.$emit('update:pending', false);
          this.$emit('updated', data);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.$emit('update:pending', true);
      this.addProductSetType({
        data: {
          name: this.name,
          isDefault: this.isDefault,
        },
      })
        .then(({ data }) => {
          this.name = '';
          this.$emit('update:pending', false);
          this.$emit('updated', data);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
    remove() {
      if (this.type.isDefault) {
        this.errDefault = true;
        return;
      }

      this.$emit('update:pending', true);
      this.removeProductSetType({
        params: {
          id: this.type.id,
        },
      })
        .then(({ data }) => {
          this.$emit('update:pending', false);
          this.$emit('updated', data);
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.error = data.title;
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.type) {
      this.isDefault = this.type.isDefault;
      this.name = this.type.name;
    }
  },
};
</script>

<style>

</style>
